import React, { useState, useEffect } from 'react';
import Badge from 'react-bootstrap/Badge';
import './PumpCard.css'
import { NozzleArrayModal } from '../../ArrayCreators/NozzleArrayCreator';
// import TotalizerContext from './TotalizerContext.js';
import { TotalizerModal } from './PumpModals.js';
import socketIOClient from "socket.io-client";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Swal from 'sweetalert2/dist/sweetalert2.js';

//PumpCard Component
export const PumpCard = (props) => {

  const [NozzleArrayModalShow, setNozzleArrayModalShow] = useState(false);
  const [TotalizerModalShow, setTotalizerModalShow] = useState(false);
  const [pumpStatus, setPumpStatus] = useState(props.connectionStatus);
  const [amountResponse, setAmountResponse] = useState(0);
  const [volumeResponse, setVolumeResponse] = useState(0);
  const [priceResponse, setPriceResponse] = useState(0);
  const [statusResponse, setStatusResponse] = useState(1);
  const [presetAmount, setPresetAmount] = useState({presetAmount: ""});
  const abortController = new AbortController();

  const handleChange = e => {
    const value = e.target.value;
    const name = e.target.name;

    setPresetAmount(prevState => ({ ...prevState, [name]: value }));
  };

  function PresetAmountSocketStart (presetAmount){
    const socket = socketIOClient(`http://${sessionStorage.getItem("PumpIp")}/pump_chat`);
    socket.emit('preset_amount', {data: presetAmount.presetAmount, pump_id: props.idCode})
  }

  function PresetAmountSocketSuspend (){
    const socket = socketIOClient(`http://${sessionStorage.getItem("PumpIp")}/pump_chat`);
    socket.emit('suspend', {data: true, pump_id: props.idCode})
  }
  
  function PresetAmountSocketResume (){
    const socket = socketIOClient(`http://${sessionStorage.getItem("PumpIp")}/pump_chat`);
    socket.emit('resume', {data: true, pump_id: props.idCode})
  }
  
  useEffect(() => {
    const abortController = new AbortController();
    const socket = socketIOClient(`http://${sessionStorage.getItem("PumpIp")}/pump_chat`);

    socket.on('filling_info', data => {
    if(props.idCode === data.pump_id )
    { 
     setAmountResponse(data.amount);
     setVolumeResponse(data.volume);
     setPriceResponse(data.price);
    }
     });
     
     socket.on('nozzle_status', data => {
      if(props.idCode === data.pump_id)
    {
      setStatusResponse(data.nozzle_status)
    }

    socket.on('pump_status', data => {
      if(props.idCode === data.identification_code)
    {
      setPumpStatus(data.is_connected)
    }
    })
  })

  return () => {

    abortController.abort();}
    }, 
    [props.idCode]);

  function statusSubmit() {

    if (props.token.user_record.roles.id <= 4 )
    {
      pumpStatus ?
    Swal.fire({
      title: 'Are you sure?',
        text: "This will disconnect the pump!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Disconnect'
    }).then((result) => {
      if (result.isConfirmed) {
      fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/pump/connect_disconnect_pump?pump_id=${props.id}&is_connected=0`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': props.token.access_token },
      body: JSON.stringify(null),
      signal: abortController.signal,
    }).then(
      Swal.fire(
      'Disconnected!',
      'Pump is now disconnected.',
      'success'
  ))
      }
  })
    : fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/pump/connect_disconnect_pump?pump_id=${props.id}&is_connected=1`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': props.token.access_token },
      body: JSON.stringify(null),
      signal: abortController.signal,
    })
    props.setCheckChange(props.checkChange + 1);
    }
    else
    {
      Swal.fire({
        title: 'Error!',
        text: 'You Are Not Authorized',
        icon: 'error',
        confirmButtonText: 'Close',
      })
    }
  }

  if(props.approvalType === "Conditional Approval" || props.approvalType === "Auto Authorize"){
  return (
    <>
      <NozzleArrayModal LogoutTimeout={props.LogoutTimeout} token = {props.token} show={NozzleArrayModalShow} onHide={() => setNozzleArrayModalShow(false)} NozzlePumpID={props.id} idCode={props.idCode} label = {props.label}  connectionStatus = {pumpStatus}/>
      <TotalizerModal LogoutTimeout={props.LogoutTimeout} token = {props.token} show={TotalizerModalShow} onHide={() => setTotalizerModalShow(false)} NozzlePumpID={props.id} />
      <div className="PuC" style={statusResponse ? {background: "#FFFFFF00"} : {background: "orange"}}>

        {/*variant={(props.is_disconnected === 0) ? "success" : "danger"}*/}

          <div className='pumpCardSection'>

          <div className='PuCInfo' id="NozzleButton" onClick={() => setNozzleArrayModalShow(true)}>
            <Badge id="NozzleBadge" pill>{props.label} ({parseInt(props.pump_identification_code, 16)})</Badge>
          {/*<Badge className= 'tl' pill >{(props.is_disconnected === 0) ? "Connected" : "Disconnected"}</Badge>*/}
            <Badge id="NozzleBadge" pill><span>Nozzles: </span>{props.numberOfNozzles}</Badge>
            </div>

            <div style={pumpStatus ? {background: "#7EC8ECAA"} : {background: "#E14B4B"}} id="StatusButton" className='PuCInfo' onClick={() => statusSubmit()}>
            <p className='PumpStatusButton'>Status: </p>
            <Badge id="StatusBadge">{(pumpStatus) ? "Connected" : "Disconnected"}</Badge>
            </div>

            <div id="TotalizerButton" className='PuCInfo' onClick={() => setTotalizerModalShow(true)}>
            <p className='PumpTotalizerButton'><b>Pump Totalizer Data</b></p>
            {/*<TotalizerContext.Consumer>
            {(totalizerData) => <Badge>{totalizerData.totalizer}</Badge>}
            </TotalizerContext.Consumer>*/}
            </div>

            <div className='PuCInfo'>
            <p className='pumpButton'>Total: </p>
            <Badge className="PumpBadges">{amountResponse}</Badge>
            </div>

            <div className='PuCInfo'>
            <p className='pumpButton'>Volume: </p>
            <Badge className="PumpBadges">{volumeResponse}L</Badge>
            </div>

            <div className='PuCInfo'>
            <p className='pumpButton'>Unit Price: </p>
            <Badge className="PumpBadges">{priceResponse}/L</Badge>
            </div>

            <br/>
            {/* <p><b>{props.approvalType === "Conditional Approval" ? "CA" : "AA"}</b></p> */}
          </div>
        </div>
    </>
  );}
  else if (props.approvalType==="Pre Paid" || props.approvalType === "Post Paid"){
    return (
      <>
      <NozzleArrayModal LogoutTimeout={props.LogoutTimeout} token = {props.token} show={NozzleArrayModalShow} onHide={() => setNozzleArrayModalShow(false)} NozzlePumpID={props.id} idCode={props.idCode} label = {props.label}  connectionStatus = {pumpStatus}/>
      <TotalizerModal LogoutTimeout={props.LogoutTimeout} token = {props.token} show={TotalizerModalShow} onHide={() => setTotalizerModalShow(false)} NozzlePumpID={props.id} />
      <div className="PuC" style={statusResponse ? {background: "#FFFFFF00"} : {background: "orange"}}>

          <div className='pumpCardSection'>

          <div className='PuCInfo'id="NozzleButton" onClick={() => setNozzleArrayModalShow(true)}>
            <Badge id="NozzleBadge" pill>{props.label} ({parseInt(props.pump_identification_code, 16)})</Badge>
          {/*<Badge className= 'tl' pill >{(props.is_disconnected === 0) ? "Connected" : "Disconnected"}</Badge>*/}
            <Badge id="NozzleBadge" pill>Nozzles: {props.numberOfNozzles}</Badge>
            </div>

            <div style={pumpStatus ? {background: "#7EC8ECAA"} : {background: "#E14B4B"}} id="StatusButton" className='PuCInfo' onClick={() => statusSubmit()}>
            <p className='PumpStatusButton'>Status: </p>
            <Badge id="StatusBadge">{(pumpStatus) ? "Connected" : "Disconnected"}</Badge>
            </div>

            <div id="TotalizerButton" className='PuCInfo' onClick={() => setTotalizerModalShow(true)}>
            <p className='PumpTotalizerButton'><b>Pump Totalizer Data</b></p>
            {/*<TotalizerContext.Consumer>
            {(totalizerData) => <Badge>{totalizerData.totalizer}</Badge>}
            </TotalizerContext.Consumer>*/}
            </div>

            <div className='PuCInfo'>
            <p className='pumpButton'>Total: </p>
            <Badge>{amountResponse}</Badge>
            </div>

            <div className='PuCInfo'>
            <p className='pumpButton'>Volume: </p>
            <Badge>{volumeResponse}L</Badge>
            </div>

            <div className='PuCInfo'>
            <p className='pumpButton'>Unit Price: </p>
            <Badge>{priceResponse}/L</Badge>
            </div>

            <div className='PuCInfo'>
            <Form.Control size="sm" type="number" placeholder="Preset Amount" name="presetAmount" value={presetAmount.presetAmount} onChange={handleChange} />
            <Button id="StartButton" size="sm" variant="FFFFFF" title="Start" type="submit" onClick={() => PresetAmountSocketStart(presetAmount)}>Start</Button>
          </div>
          <div id='PrePaidButtons'>
            <Button size="sm" variant="danger" title="Suspend" onClick={() => PresetAmountSocketSuspend()}>Suspend</Button>
            <Button size="sm" variant="secondary" title="Resume" onClick={() => PresetAmountSocketResume()}>Resume</Button>
            </div>
          </div>
        </div>
    </>
    );
  }
}
