import React, { useState, useEffect } from 'react';
import { NozzleCard } from '../components/NozzleComponents/NozzleCard';
import { Modal } from 'react-bootstrap';
import './NozzleArrayCreator.css';
import Button from 'react-bootstrap/Button';
import Background from "./Background.jpg";
import { AddNozzleModal, DeleteNozzleModal, EditNozzleModal } from '../components/NozzleComponents/NozzleModals';
import {NotAuthorizedAlert} from '../components/NavigationComponents/NotAuthorized/NotAuthorized';

export const NozzleArrayCreator = ({ArrayNozzlePumpID, idCode, connectionStatus}) => {


    const [nozzleArr, setNozzleArr] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();

        fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/nozzle/page?pump_ids=` + ArrayNozzlePumpID,
            {
                headers: { 'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token },
                signal: abortController.signal,
            })
            .then(response => response.json())
            .then(data => setNozzleArr(data.data.page_records))

            return () => {abortController.abort();}
    }, [ArrayNozzlePumpID]);

    return (

        nozzleArr.map((item, index) =>
            <NozzleCard
                key={index}
                nozzleOrder={index + 1}
                nozzleId={item.id}
                is_disconnected={item.suspended}
                nozzle_number={item.nozzle_number}
                status={item.status}
                nozzlePumpIdCode={idCode}
                connectionStatus={connectionStatus}
                />)
    )
}

export const NozzleArrayModal = ({LogoutTimeout, show, onHide, label, token, NozzlePumpID, idCode, connectionStatus}) => {

    const [modalShowAdd, setModalShowAdd] = useState(false);
    const [modalShowEdit, setModalShowEdit] = useState(false);
    const [modalShowDelete, setModalShowDelete] = useState(false);


    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Pump {label} Nozzles
                </Modal.Title>
            </Modal.Header>
            <div style={{ backgroundImage: `url(${Background})`, backgroundSize: "cover" }}>
                <div className="Buttons">
                    <button className="fas fa-plus-circle" onClick={() => {if( token.user_record.roles.id >= 4){NotAuthorizedAlert()} else{setModalShowAdd(true)}}} title="Add"></button>
                    <AddNozzleModal show={modalShowAdd} onHide={() => setModalShowAdd(false)} NozzleAddModalPumpID={NozzlePumpID} token = {token} />
                    {/* <button className="fas fa-trash-alt" onClick={() => {if( token.user_record.roles.id >= 4){NotAuthorizedAlert()} else{setModalShowDelete(true)}}} title="Delete"></button> */}
                    <DeleteNozzleModal show={modalShowDelete} onHide={() => setModalShowDelete(false)} NozzleDeleteModalPumpID={NozzlePumpID} token = {token} LogoutTimeout = {LogoutTimeout} />
                    <button className="fas fa-pencil-alt" onClick={() => {if( token.user_record.roles.id >= 4){NotAuthorizedAlert()} else{setModalShowEdit(true)}}} title="Edit"></button>
                    <EditNozzleModal show={modalShowEdit} onHide={() => setModalShowEdit(false)} NozzleEditModalPumpID={NozzlePumpID} token = {token} LogoutTimeout = {LogoutTimeout}/>
                    <button className="fas fa-sync-alt" onClick={() => window.location.reload(false)} title="Refresh"></button>
                </div>
                <Modal.Body >

                    <NozzleArrayCreator LogoutTimeout={LogoutTimeout}  token = {token} ArrayNozzlePumpID={NozzlePumpID} idCode={idCode} connectionStatus = {connectionStatus}/>

                </Modal.Body>
            </div>
            <Modal.Footer>
                <Button onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}

export const NozzleArrayData = ({nozzlePumpId }) => {

    const [nozzleArrayData, setNozzleArrayData] = useState([]);

    useEffect(() => {

        fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/nozzle/page?pump_ids=` + nozzlePumpId,
            {
                headers: { 'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token },
            })
            .then(response => response.json())
            .then(data => setNozzleArrayData(data.data.page_records))
    }, [nozzlePumpId]);

    if (!isNaN(nozzlePumpId)) return (
        nozzleArrayData.map((item, index) =>

            <option key={index} value={item.id}>Nozzle: {index + 1}, Pump: {nozzlePumpId}</option>

        )
    )
    else return 0;
}

export const FilterNozzleArrayData = ({nozzlePumpId, toggleButton}) => {

    const [nozzleArrayData, setNozzleArrayData] = useState([]);

    useEffect(() => {
        if(toggleButton === "block"){
        if (nozzlePumpId === "") {
            fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/nozzle/page`, {
                headers: { 'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token },
            })
                .then(response => response.json())
                .then(data => setNozzleArrayData(data.data.page_records))
        }
        else if (nozzlePumpId !== "") {
            fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/nozzle/page?` + nozzlePumpId.slice(0, -1), {
                headers: { 'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token },
            })
                .then(response => response.json())
                .then(data => setNozzleArrayData(data.data.page_records))
        }}
    }, [nozzlePumpId, toggleButton]);

    return (
        nozzleArrayData.map((item, index) =>

            <option key={index} value={`nozzle_ids=${item.id}&`}>Nozzle Name: {item.name}, Nozzle Number: {item.id}.</option>

        )
    )
}