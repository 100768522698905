import React from 'react';
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button';
import './ProbeCard.css'

//PumpCard Component
export const ProbeCard = (props) => {

  return (
    <div id="NoC" style={{background: "#7EC8ECAA"}}>

      <div className='flex flex-column'>
          <div className= 'mb1 tl'>
          </div>

          <div className= 'mb1 tl'>
          <Badge pill>Probe ({props.name}) Info</Badge>
          </div>

      </div>
      <div>

      <div className="probeCardSection">
      <Button className= 'probeButton' size="sm">
      Dangerous Fuel Level (Upper): <Badge variant="light">{props.product_HH}mm</Badge>
      </Button>
      <Button className= 'probeButton' size="sm">
      Maximum Fuel Level (Upper): <Badge variant="light">{props.product_H}mm</Badge>
      </Button>
      <Button className= 'probeButton' size="sm">
      Minimum Fuel Level (Lower): <Badge variant="light">{props.product_L}mm</Badge>
      </Button>
      <Button className= 'probeButton' size="sm">
      Dangerous Fuel Level (Lower): <Badge variant="light">{props.product_LL}mm</Badge>
      </Button>
      <Button className= 'probeButton' size="sm">
      Dangerous Water Level (Upper): <Badge variant="light">{props.water_H}mm</Badge>
      </Button>
      <Button className= 'probeButton' size="sm">
      Dangerous Water Level (Lower): <Badge variant="light">{props.water_L}mm</Badge>
      </Button>
      <Button className= 'probeButton' size="sm">
      Maximum Temperature: <Badge variant="light">{props.temperature_H}°C</Badge>
      </Button>
      <Button className= 'probeButton' size="sm">
      Minimum Temperature: <Badge variant="light">{props.temperature_L}°C</Badge>
      </Button>
      </div>

      </div>
    </div>
  );
}
