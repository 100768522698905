import React, { useState, useEffect } from "react";
import { TankGauge } from "../components/TankComponents/TankGauge";
import Swal from "sweetalert2/dist/sweetalert2.js";

export const TankArrayCreator = ({ token, tankArrayCheckChange }) => {
  const [tankArray, setTankArray] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();

    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/tank/page`, {
      headers: { Authorization: JSON.parse(sessionStorage.getItem('token')).data.access_token },
      signal: abortController.signal,
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Response not OK.", response);
      }
      return response.json();
    })
      .then((data) => setTankArray(data.data.page_records))
      .catch((e) => {
        Swal.fire({
          title: "Error!",
          text: "Connection problem.",
          icon: "error",
          confirmButtonText: "Close",
        });
      });

      return () => {abortController.abort();}
        
  }, [tankArrayCheckChange]);

  return tankArray.map((item, index) => (
    <TankGauge
      token={token}
      key={index}
      ProbeID={item?.probes?.[0]}
      name={item.name}
      id={item.id}
      product={item.products_info.name}
      price={item.products_info.litre_price}
      value={(item.latest_payload?._pvolume*100)/item.capacity}
      capacity={item.capacity}
      description={item.description}
      temperature={item?.latest_payload?._temperature}
    />
  ));
};

export const TankArrayData = (props) => {
  const [tankArrayData, setTankArrayData] = useState([]);

  useEffect(() => {
    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/tank/page`, {
      headers: { Authorization: JSON.parse(sessionStorage.getItem('token')).data.access_token },
    })
      .then((response) => response.json())
      .then((data) => setTankArrayData(data.data.page_records));
  }, []);

  return tankArrayData.map((item, index) => (
    <option key={index} value={item.id}>
    Tank Description: {item.description}
    </option>
  ));
};

export const FilterTankArrayData = ({toggleButton}) => {
  const [filterTankArrayData, setFilterTankArrayData] = useState([]);

  useEffect(() => {

    if(toggleButton === "block"){
    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/tank/page`, {
      headers: { Authorization: JSON.parse(sessionStorage.getItem('token')).data.access_token },
    })
      .then((response) => response.json())
      .then((data) => setFilterTankArrayData(data.data.page_records));}
  }, [toggleButton]);

  return filterTankArrayData.map((item, index) => (
    <option key={index} value={`tank_ids=${item.id}&`}>
      Tank Name: {item.name}.
    </option>
  ));
};

export const ProductData = (props) => {
  const [ProductData, setProductData] = useState([]);

  useEffect(() => {
    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/product/page`, {
      headers: { Authorization: JSON.parse(sessionStorage.getItem('token')).data.access_token },
    })
      .then((response) => response.json())
      .then((data) => setProductData(data.data.page_records));
  }, []);

  return (
    <>
      {ProductData.map((item, index) => (
        <option key={index} value={item.id}>
          Product Name: {item.name}.
        </option>
      ))}
    </>
  );
};

export const FilterProductData = ({toggleButton}) => {
  const [filterProductData, setFilterProductData] = useState([]);

  useEffect(() => {
    if(toggleButton === "block"){
    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/product/page`, {
      headers: { Authorization: JSON.parse(sessionStorage.getItem('token')).data.access_token },
    })
      .then((response) => response.json())
      .then((data) => setFilterProductData(data.data.page_records));}
  }, [toggleButton]);

  return filterProductData.map((item, index) => (
    <option key={index} value={`product_ids=${item.id}&`}>
      Product Name: {item.name}.
    </option>
  ));
};
