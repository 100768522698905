import React, { useState, useEffect, useCallback } from 'react';
import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import './PumpModals.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { PumpArrayData, PumpProtocolData, PumpComPortData, PumpApprovalTypeData } from '../../ArrayCreators/PumpArrayCreator';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export function AddPumpModal({token, LogoutTimeout, checkChange, setCheckChange, show, onHide}) {

const [state, setState] = useState({});
const [cardReadersArray, setCardReadersArray] = useState([]);
const [printersArray, setPrintersArray] = useState([]);
const [systemState, setSystemState] = useState({system: ''});
const [errors, setErrors] = useState({});
const [formError, setFormError] = useState("");

const handleChange = e => {
  let value;
  const name = e.target.name;
  if(e.target.name === "card_reader_id" && e.target.value === ""){
    setState(prevState => ({...prevState, [name]: null}));
  }
  else if (!isNaN(e.target.value) && e.target.value.length > 0 && e.target.name !== "pump_identification_code")
  {value = Number(e.target.value);}
  else {value = e.target.value;}

  setState(prevState => ({...prevState, [name]: value}));
  
  if(!!errors[name]){setErrors({...errors, [name]: null})};
};

const handleSystemChange = e => {
  const value = e.target.value;
  const name = e.target.name;

  setSystemState(prevState => ({...prevState, [name]: value}));
};

const createOptionsCode = (value) =>{
  var elements = [];
  for (let i=0; i<value; i++){
    elements.push(<option key={i} value={i}>{i}</option>);
  }
  return elements;
}

const createOptionsCodeS4 = () => {
  var elements = [];
  for (let i = 0; i <= 99; i++) {
    const hex = i.toString(16).padStart(2, '0');
    const displayValue = i < 10 ? `0${i}` : i;
    elements.push(<option key={hex} value={hex}>{displayValue}</option>);
  }
  return elements;
}


const createOptionsAddress = (value) =>{
  var elements = [];
  for (let i=1; i<value; i++){
    elements.push(<option key={i} value={i}>{i}</option>);
  }
  return elements;
}

const createIdentificationCodes = () =>{

  if(systemState.system !== "PTS"){
    return  <Form.Group controlId="formPumpID">
              <Form.Label>Pump Identification Code</Form.Label>
              <Form.Control isInvalid={!!errors.pump_identification_code} disabled={systemState.system !== "S4"} as="select" type="number" value={state.pump_identification_code} onChange={handleChange} name="pump_identification_code">
              <option>Select Pump Identification Code</option>
            {createOptionsCodeS4()}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.pump_identification_code}</Form.Control.Feedback>
            </Form.Group>
  }
  else if(systemState.system === "PTS"){
    return  <Form.Group controlId="formPumpID">
              <Form.Label>Pump Identification Code</Form.Label>
              <Form.Control isInvalid={!!errors.pump_identification_code} as="select" type="number" value={state.pump_identification_code} onChange={handleChange} name="pump_identification_code">
              <option>Select Pump Identification Code</option>
            {createOptionsCode(16)}
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.pump_identification_code}</Form.Control.Feedback>
            </Form.Group>
  }
}

function finalizePTS() {
  fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/pump/upload_pumps_configurations`, 
{
method: 'PUT',
headers: { 'Content-Type': 'application/json' ,'Authorization': token.access_token},
body: JSON.stringify(null)
})
.then((response) => {
if (!response.ok) {
  throw new Error("Response not OK.", response);
}
return response.json();
})
.then((data) => {
Swal.fire({
  text: data.message+'.',
  icon: 'info',
  confirmButtonText: 'Close',
  confirmButtonColor: '#F27474'
})
})
.catch((e) => {
Swal.fire({
  title: 'Error!',
  text: 'Could not connect to server.',
  icon: 'error',
  confirmButtonText: 'Try Again',
  confirmButtonColor: '#F27474'
})
});
}

const clearState = () => {

    setState({})
    setSystemState({system: ""});
    setErrors({});
    setFormError("");
  }

  function submit() {

  let addValues = Object.fromEntries(Object.entries(state).filter(([_, v]) => v !== ''));

if(!systemState.system){
    Swal.fire({
    title: 'Error!',
    text: 'Please Select Automation System!',
    icon: 'error',
    confirmButtonText: 'Try Again',
    confirmButtonColor: '#F27474'
  })
}
    else {
    //e.preventDefault()
    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/pump/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': token.access_token },
      body: JSON.stringify(addValues)
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Response not OK.', response);
      }
      return response.json();
    })
    .then(data => {
      if(data.status_code === 400)
      { Swal.fire({
        title: 'Error!',
        text: data.message,
        icon: 'error',
        confirmButtonText: 'Close', 
      })}
      else if (data.status_code === 630) {
        setFormError("This pump already exists")
      }
      else if(data.status_code === 403)
      { Swal.fire({
        title: 'Error!',
        text: 'You Are Not Authorized',
        icon: 'error',
        confirmButtonText: 'Close', 
      })}
      else if(data.status_code < 200 || (data.status_code >= 299))
      {
        setErrors(data.data.body.wrong_arguments);
        setFormError("Check your input!");
      }
      else if(data.status_code >= 200 && data.status_code <= 299)
      { Swal.fire({
        title: 'Success!',
        text: 'Added Successfully',
        icon: 'success',
        confirmButtonText: 'Close',
        confirmButtonColor: '#A5DC86'   
      })}
      })
      .catch((e) => {
        Swal.fire({
          title: 'Error!',
          text: 'Connection problem.',
          icon: 'error',
          confirmButtonText: 'Close',
        });
    });

    setCheckChange(checkChange+1);
  }}

  useEffect(() => {

    if(show){
      fetch(`http://${sessionStorage.getItem("ReaderIp")}/v1/card_reader/page`, 
      {
        headers: { 'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token },
      })
      .then(response => response.json())
      .then(data => setCardReadersArray(data.data.page_records))

      fetch(`http://${sessionStorage.getItem("ReaderIp")}/v1/receipt_printers/page`, 
      {
        headers: { 'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token },
      })
      .then(response => response.json())
      .then(data => setPrintersArray(data.data.page_records))
    }

  }, [show]);

  return (
    <Modal show={show} onHide={() => {onHide(); clearState();}}>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Add New Pump
          </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Form>
        <Form.Group controlId="formSystem">
            <Form.Label>Automation System</Form.Label>
            <Form.Control as="select" type="bool" value={systemState.system} onChange={handleSystemChange} name="system">
            <option >Select Automation System</option>
            <option value="PTS">PTS</option>
            <option value="S4">S4</option>
            </Form.Control>
          </Form.Group>

          {systemState.system === "PTS" ? <><br/><p><b>Required: </b>Press finalize <i>after</i> all pumps are added.</p> <Button variant="primary" onClick={finalizePTS}>Finalize</Button> <br/></> : null}
          <hr/>

        {/* PTS-specific settings start */}
        <Form.Group controlId="formComProtocol">
            <Form.Label>Communication Protocol ID</Form.Label>
            <Form.Control isInvalid={!!errors.protocol_id} as="select" type="number" placeholder="Select Communication Protocol ID" value={state.protocol_id} onChange={handleChange} name="protocol_id">
            <option value="">Select Protocol ID</option>
            <PumpProtocolData token={token} />
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.protocol_id}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formPumpChannel">
            <Form.Label>Communication Channel</Form.Label>
            <Form.Control isInvalid={!!errors.channel} as="select" disabled={systemState.system !== "PTS"} type="number" value={state.channel} onChange={handleChange} name="channel">
            <option value="">Select Communication Channel</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.channel}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formPumpAddress">
            <Form.Label>Communication Address</Form.Label>
            <Form.Control isInvalid={!!errors.pump_address} as="select" disabled={systemState.system !== "PTS"} type="number" value={state.pump_address} onChange={handleChange} name="pump_address">
            <option value="">Select Communication Address</option>
            {createOptionsAddress(100)}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.pump_address}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formPumpID">
            <Form.Label>Baud Rate</Form.Label>
            <Form.Control isInvalid={!!errors.baud} as="select" disabled={systemState.system !== "PTS"} type="number" value={state.baud} onChange={handleChange} name="baud">
              <option value="">Select Baud Rate</option>
              <option value={75}>75</option>
              <option value={110}>110</option>
              <option value={134}>134</option>
              <option value={150}>150</option>
              <option value={300}>300</option>
              <option value={600}>600</option>
              <option value={1200}>1200</option>
              <option value={1800}>1800</option>
              <option value={2400}>2400</option>
              <option value={4800}>4800</option>
              <option value={7200}>7200</option>
              <option value={9600}>9600</option>
              <option value={14400}>14400</option>
              <option value={19200}>19200</option>
              <option value={38400}>38400</option>
              <option value={57600}>57600</option>
              <option value={115200}>115200</option>
              <option value={128000}>128000</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.baud}</Form.Control.Feedback>
          </Form.Group>
          {/* PTS-specific settings end */}

          {createIdentificationCodes()} {/* Create field for pump identification code */}

          <Form.Group controlId="formPumpName">
            <Form.Label>Pump Name</Form.Label>
            <Form.Control isInvalid={!!errors.name} type="text" maxLength="10" placeholder="Enter Pump Name" value={(state.name)} onChange={handleChange} name="name" />
            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formPumpName">
            <Form.Label>Display Label</Form.Label>
            <Form.Control isInvalid={!!errors.display_label} type="text" placeholder="Enter Pump Display Label" value={(state.display_label)} onChange={handleChange} name="display_label" />
            <Form.Control.Feedback type="invalid">{errors.display_label}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formPumpName">
            <Form.Label>COM Port</Form.Label>
            <Form.Control isInvalid={!!errors.com_id} disabled={systemState.system !== "S4"} as="select" type="number" value={state.com_id} onChange={handleChange} name="com_id">
            <option value="">Select COM port</option>
            <PumpComPortData token={token} />
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.com_id}</Form.Control.Feedback>
          </Form.Group>


          <Form.Group controlId="formPumpName">
            <Form.Label>Approval Type</Form.Label>
            <Form.Control isInvalid={!!errors.approval_type_id} as="select" type="number" placeholder="Select Pump Approval Type" value={state.approval_type_id} onChange={handleChange} name="approval_type_id">
            <option value="">Select Approval Type</option>
              <PumpApprovalTypeData token={token} />
              </Form.Control>
              <Form.Control.Feedback type="invalid">{errors.approval_type_id}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formPumpName">
            <Form.Label>Card Authorization</Form.Label>
            <Form.Control isInvalid={!!errors.card_authorization} as="select" type="number" placeholder="Enter Card Authorization" value={(state.card_authorization)} onChange={handleChange} name="card_authorization">
            <option value="">Select Card Authorization</option>
            <option value={1}>Card Enabled</option>
            <option value={0}>Not Card Enabled</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.card_authorization}</Form.Control.Feedback>
          </Form.Group>

        <Form.Group controlId="formCardReader">
            <Form.Label>Card Reader</Form.Label>
            <Form.Control isInvalid={!!errors.card_reader_id} as="select" type="number" placeholder="Select Card Reader" value={(state.card_reader_id)} onChange={handleChange} name="card_reader_id">
            <option >Select Card Reader</option>
            <option value="">Without a card reader</option>
            {
            cardReadersArray.map((item, index) =>

            <option key={index} value={item.id}>{item.identification_code}</option>
            )
          }
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.card_reader_id}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formPrinter">
            <Form.Label>Printer</Form.Label>
            <Form.Control isInvalid={!!errors.printer_id} as="select" type="number" placeholder="Select Printer" value={(state.printer_id)} onChange={handleChange} name="printer_id">
            <option value="">Select Printer</option>
            {
            printersArray.map((item, index) =>

            <option key={index} value={item.id}>{item.name}</option>
            )
          }
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.printer_id}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formPumpName">
            <Form.Label>Initial Totalizer Value</Form.Label>
            <Form.Control isInvalid={!!errors.initial_totalizer} type="number" placeholder="Enter Pump Totalizer Value" value={(state.initial_totalizer)} onChange={handleChange} name="initial_totalizer" />
            <Form.Control.Feedback type="invalid">{errors.initial_totalizer}</Form.Control.Feedback>
          </Form.Group>

          <FormHelperText error={!!Object.keys(errors).length || formError !== ""}><b>{formError}</b></FormHelperText>

          <br/>
          <Button variant="primary" onClick={submit}>
            Add
          </Button>
        </Form>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {onHide(); clearState();}}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export function DeletePumpModal(props) {
  const [state, setState] = useState({  pump_id: 0 });

  
  const handleChange = e => {
    let value;
    const name = e.target.name;
    if (!isNaN(e.target.value) && e.target.value.length > 0)
    {value = Number(e.target.value)}
    else {value = e.target.value}
  
    setState(prevState => ({...prevState, [name]: value}));
  };

  const clearState = () => {

    setState({
      pump_id: ""
    })
  }

  function submit() {
    
    //e.preventDefault()
    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/pump/${state.pump_id}` , {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': props.token.access_token },
      body: JSON.stringify(null)
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Response not OK.', response);
      }
      return response.json();
    }).then(data => {
      if(data.status_code < 200 || data.status_code > 299)
      { Swal.fire({
        title: 'Error!',
        text: 'Check your input!',
        icon: 'error',
        confirmButtonText: 'Try Again',
        confirmButtonColor: '#F27474'
    
      })}
      else if(data.status_code >= 200 && data.status_code <= 299)
      { Swal.fire({
        title: 'Success!',
        text: 'Deleted Successfully',
        icon: 'success',
        confirmButtonText: 'Close',
        confirmButtonColor: '#A5DC86'
      })}
      })
      .catch((e) => {
        Swal.fire({
          title: 'Error!',
          text: 'Connection problem.',
          icon: 'error',
          confirmButtonText: 'Close',
        });
    });
    props.setCheckChange(props.checkChange+1);
  }
  return (
    <Modal show={props.show} onHide={() => { props.onHide(); clearState();}}>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Existing Pump
           </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Form>
          
        <Form.Group controlId="pumpDeleteDropdown">
            <Form.Label>Select Pump to Delete</Form.Label>
            <Form.Control as="select" placeholder="Select Pump" value = {state.pump_id} onChange = {handleChange} name = "pump_id">
            <option >Select Pump to Delete.</option>
              <PumpArrayData token={props.token}/>
            </Form.Control>
          </Form.Group>
          <br/>
          <Button variant="danger" type="submit" onClick={submit} key={handleChange}>
            Delete
   </Button>
        </Form>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => { props.onHide(); clearState();}}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export function EditPumpModal({token, checkChange, setCheckChange, show, onHide}) {

  const [state, setState] = useState({name: "", display_label:"", com_id:"", printer_id:"", protocol_id:"", approval_type_id:"", card_authorization:"", pump_address: "", channel: "", baud: "",});
  const [pumpId, setpumpId] = useState({id: ""});
  const [cardReadersArray, setCardReadersArray] = useState([]);
  const [printersArray, setPrintersArray] = useState([]);


  const handleChange = e => {
    let value;
    const name = e.target.name;
    if (!isNaN(e.target.value) && e.target.value.length > 0)
    {value = Number(e.target.value)}
    else {value = e.target.value;}
  
    setState(prevState => ({...prevState, [name]: value}));
  };

  const handlePumpChange = e => {
    
    let value;
    const name = e.target.name;
    if (!isNaN(e.target.value) && e.target.value.length > 0)
    {value = Number(e.target.value)}
    else {value = e.target.value;}
  
    setpumpId(prevState => ({...prevState, [name]: value}));

    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/pump/`+ e.target.value , {
      headers: { 'Authorization': token.access_token },
    }
     )
    .then(response => response.json())
    .then(data => setState({
      name: data.data.name,
      display_label: data.data.display_label,
      com_id: data.data.coms?.id,
      protocol_id: data.data.protocols.id,
      approval_type_id: data.data.approval_types.id,
      card_reader_id: data.data?.card_reader_id,
      card_authorization: data.data.card_authorization,
      pump_address: data.data?.pump_address,
      channel: data.data?.channel,
      baud: data.data?.baud,
      printer_id: data.data?.printer_id   
    }))
  }

  // const createOptions = (value) =>{
  //   var elements = [];
  //   for (let i=0; i<value; i++){
  //     elements.push(<option value={i}>{i}</option>);
  //   }
  //   return elements;
  // }

  const clearState = () => {

    setState({
      name: "",
      display_label: "",
      com_id: "",
      protocol_id: "",
      approval_type_id: "",  
      card_authorization:"", 
      card_reader_id:"",
      channel: "",
      baud: "",
      pump_address: "",
      printer_id:""
    })
    setpumpId({id: ""})
  }

  function submit() {
    if(state.com_id){
      fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/pump/${pumpId.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': token.access_token },
      body: JSON.stringify({
      pump_identification_code: state.pump_identification_code,
      name: state.name,
      display_label: state.display_label,
      protocol_id: state.protocol_id,
      approval_type_id: state.approval_type_id,
      card_reader_id: state.card_reader_id,
      initial_totalizer: state.initial_totalizer,
      card_authorization: state.card_authorization,
      printer_id: state?.printer_id,
      com_id: state.com_id})
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Response not OK.', response);
      }
      return response.json();
    }).then(data => {
      if(data.status_code < 200 || data.status_code > 299)
      { Swal.fire({
        title: 'Error!',
        text: 'Check your input!',
        icon: 'error',
        confirmButtonText: 'Try Again',
        confirmButtonColor: '#F27474'
    
      })}
      else if(data.status_code >= 200 && data.status_code <= 299)
      { Swal.fire({
        title: 'Success!',
        text: 'Edited Successfully',
        icon: 'success',
        confirmButtonText: 'Close',
        confirmButtonColor: '#A5DC86'
        
    
      })}
      })
      .catch((e) => {
        Swal.fire({
          title: 'Error!',
          text: 'Connection problem.',
          icon: 'error',
          confirmButtonText: 'Close',
        });
    });
    setCheckChange(checkChange+1);
    }
    else {
    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/pump/${pumpId.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': token.access_token },
      body: JSON.stringify({
        pump_identification_code: state.pump_identification_code,
        // pump_address: state.pump_address,
        name: state.name,
        display_label: state.display_label,
        protocol_id: state.protocol_id,
        approval_type_id: state.approval_type_id,
        card_reader_id: state.card_reader_id,
        initial_totalizer: state.initial_totalizer,
        card_authorization: state.card_authorization,
        printer_id: state?.printer_id,
        // channel: state.channel,
        // baud: state.baud
      })
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Response not OK.', response);
      }
      return response.json();
    }).then(data => {
      if(data.status_code < 200 || data.status_code > 299)
      { Swal.fire({
        title: 'Error!',
        text: 'Check your input!',
        icon: 'error',
        confirmButtonText: 'Try Again',
        confirmButtonColor: '#F27474'
    
      })}
      else if(data.status_code >= 200 && data.status_code <= 299)
      { Swal.fire({
        title: 'Success!',
        text: 'Edited Successfully',
        icon: 'success',
        confirmButtonText: 'Close',
        confirmButtonColor: '#A5DC86'
        
    
      })}
      })
      .catch((e) => {
        Swal.fire({
          title: 'Error!',
          text: 'Connection problem.',
          icon: 'error',
          confirmButtonText: 'Close',
        });
    });
    setCheckChange(checkChange+1);}
  }

  const fetchReaders = useCallback(() => {
    if (show){
    fetch(`http://${sessionStorage.getItem("ReaderIp")}/v1/card_reader/page`, 
      {
        headers: { 'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token },
      })
      .then(response => response.json())
      .then(data => setCardReadersArray(data.data.page_records))

      fetch(`http://${sessionStorage.getItem("ReaderIp")}/v1/receipt_printers/page`, 
      {
        headers: { 'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token },
      })
      .then(response => response.json())
      .then(data => setPrintersArray(data.data.page_records))
    }

  }, [show]);

  useEffect(() => {

    fetchReaders();

  }, [fetchReaders]);

  return (
    <Modal show={show} onHide={() => {onHide(); clearState();}}>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Existing Pump
           </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Form>

        <Form.Group controlId="pumpEditDropdown">
            <Form.Label>Select Existing Pump to Edit</Form.Label>
            <Form.Control as="select" placeholder="Select Pump" value={pumpId.id} onChange = {handlePumpChange} name="id">
            <option value = "" disabled>Select Pump to Edit.</option>
              <PumpArrayData token={token} />
            </Form.Control>
          </Form.Group>
          <hr/>

          {/* PTS-specific settings start */}
        {/* <Form.Group controlId="formComProtocol">
            <Form.Label>Communication Protocol ID</Form.Label>
            <Form.Control disabled={!state.protocol_id} as="select" type="number" placeholder="Select Communication Protocol ID" value={state.protocol_id} onChange={handleChange} name="protocol_id">
            <option value="" disabled>Select Protocol ID</option>
            <PumpProtocolData token={token} />
            </Form.Control>
          </Form.Group> */}

          {/* <Form.Group controlId="formPumpChannel">
            <Form.Label>Communication Channel</Form.Label>
            <Form.Control disabled={!state.protocol_id} as="select" type="number" value={state.channel} onChange={handleChange} name="channel">
            <option value="" disabled>Select Communication Channel</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formPumpAddress">
            <Form.Label>Communication Address</Form.Label>
            <Form.Control disabled={!state.pump_address} as="select" type="number" value={state.pump_address} onChange={handleChange} name="pump_address">
            <option value="" disabled>Select Communication Address</option>
            {createOptions(100)}
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formPumpID">
            <Form.Label>Baud Rate</Form.Label>
            <Form.Control disabled={!state.baud} as="select" type="number" value={state.baud} onChange={handleChange} name="baud">
              <option value="" disabled>Select Baud Rate</option>
              <option value={75}>75</option>
              <option value={110}>110</option>
              <option value={134}>134</option>
              <option value={150}>150</option>
              <option value={300}>300</option>
              <option value={600}>600</option>
              <option value={1200}>1200</option>
              <option value={1800}>1800</option>
              <option value={2400}>2400</option>
              <option value={4800}>4800</option>
              <option value={7200}>7200</option>
              <option value={9600}>9600</option>
              <option value={14400}>14400</option>
              <option value={19200}>19200</option>
              <option value={38400}>38400</option>
              <option value={57600}>57600</option>
              <option value={115200}>115200</option>
              <option value={128000}>128000</option>
            </Form.Control>
          </Form.Group> */}
          {/* PTS-specific settings end */}

          <Form.Group controlId="formPumpName">
            <Form.Label>New Display Label</Form.Label>
            <Form.Control type="text" placeholder="Enter New Pump Display Label" value={state.display_label} onChange={handleChange} name="display_label" />
          </Form.Group>

          <Form.Group controlId="formPumpName">
            <Form.Label>New Com Port</Form.Label>
            <Form.Control disabled={!state.com_id} as="select" type="number" placeholder="Select New Pump Com Port" value={state.com_id} onChange={handleChange} name="com_id">
            <option>Select COM port.</option>
            <PumpComPortData token={token} />
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formPumpName">
            <Form.Label>New Protocol ID</Form.Label>
            <Form.Control as="select" type="number" placeholder="Select Pump Protocol" value={state.protocol_id} onChange={handleChange} name="protocol_id">
            <option>Select Protocol ID.</option>
            <PumpProtocolData token={token} />
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formPumpName">
            <Form.Label>New Approval Type</Form.Label>
            <Form.Control as="select" type="number" placeholder="Select New Pump Approval Type" value={state.approval_type_id} onChange={handleChange} name="approval_type_id" >
            <option>Select Approval Type.</option>
              <PumpApprovalTypeData token={token} />
              </Form.Control>
          </Form.Group>

          <Form.Group controlId="formPumpName">
            <Form.Label>Card Authorization</Form.Label>
            <Form.Control as="select" type="number" placeholder="Enter Card Authorization" value={state.card_authorization} onChange={handleChange} name="card_authorization">
            <option>Select Card Authorization</option>
            <option value={1}>Card Enabled</option>
            <option value={0}>Not Card Enabled</option>
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formCardReader">
            <Form.Label>Card Reader</Form.Label>
            <Form.Control as="select" type="number" placeholder="Select Card Reader" value={state.card_reader_id} onChange={handleChange} name="card_reader_id">
            <option>Select Card Reader</option>
            {
            cardReadersArray.map((item, index) =>

            <option key={index} value={item.id}>{item.identification_code}</option>
            )
          }
            </Form.Control>
          </Form.Group>

            <Form.Group controlId="formPrinter">
            <Form.Label>Printer</Form.Label>
            <Form.Control as="select" type="number" placeholder="Select Printer" value={(state.printer_id)} onChange={handleChange} name="printer_id">
            <option value="">Select Printer</option>
            {
            printersArray.map((item, index) =>

            <option key={index} value={item.id}>{item.name}</option>
            )
          }
            </Form.Control>
            {/* <Form.Control.Feedback type="invalid">{errors.printer_id}</Form.Control.Feedback> */}
          </Form.Group>

          <br/>
          <Button variant="warning" onClick={submit}>
            Edit
   </Button>
        </Form>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {onHide(); clearState();}}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export function TotalizerModal(props) {

const classes = useStyles();
const [state, setState] = useState({ since_date: "", to_date: "" });
const [totalizerState, setTotalizerState] = useState({mechanical_totalizer: 0, virtual_totalizer: 0});

  const handleChange = e => {
    let value;
    const name = e.target.name;
    if (!isNaN(e.target.value) && e.target.value.length > 0)
    {value = Number(e.target.value)}
    else {value = e.target.value;}
  
    setState(prevState => ({...prevState, [name]: value}));
  };

useEffect(() => {

  const abortController = new AbortController();
  let since_date = "";
  let to_date = "";

        if (state.since_date !== "") {
      since_date = `&since_date=${state.since_date.replace(/T/, " ")}&`;
    }
    if (state.to_date !== "") {
      to_date = `&to_date=${state.to_date.replace(/T/, " ")}&`;
    }
   //if (props.token.roles === 1 || props.token.roles === 2) /&since_date=${since_date}&to_date=${to_date}
   if (props.show){
  fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/pump/pump_totalizer?pump_id=${props.NozzlePumpID}` + since_date + to_date,
      {
        headers: { 'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token},
        signal: abortController.signal,
      })
      .then(response => response.json())
      .then(data => setTotalizerState(data.data))}

      return () => {abortController.abort();}
      
},[state.since_date, state.to_date, props.NozzlePumpID, props.show]);

return(
<Modal show={props.show} onHide={props.onHide}>
  <Modal.Header>
    <Modal.Title id="contained-modal-title-vcenter">Totalizer Data</Modal.Title>
  </Modal.Header>

  <Modal.Body id="TotalizerModalBody">

<div id="TotalizerModalInput">
    <FormControl className={classes.formControl} noValidate>
          <TextField
            name="since_date"
            onChange={handleChange}
            value={state.since_date}
            id="date"
            label="Since Date"
            type="datetime-local"
            className={classes.textField}
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              step: "1"
            }}
          />
          <FormHelperText className="FormHelperText">Select Start Date</FormHelperText>
        </FormControl>

        <FormControl className={classes.formControl} noValidate>
          <TextField
            name="to_date"
            onChange={handleChange}
            value={state.to_date}
            id="date"
            label="To Date"
            type="datetime-local"
            className={classes.textField}
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              step: "1"
            }}
          />
          <FormHelperText className="FormHelperText">Select End Date</FormHelperText>
        </FormControl>
</div>

<div id="TotalizerModalButtons">
<Button className="TotalizerButton" variant="primary">
  <p>Mechanical Totalizer</p><Badge className="TotalizerBadge">{totalizerState.mechanical_totalizer}</Badge>
</Button>

<Button className="TotalizerButton" variant="primary">
  <p>Virtual Totalizer</p><Badge className="TotalizerBadge">{totalizerState.virtual_totalizer}</Badge>
</Button>

<Button className="TotalizerButton" variant="primary">
  <p>Totalizer Difference</p><Badge className="TotalizerBadge">{totalizerState.vmt_difference}</Badge>
</Button>
</div>

  </Modal.Body>

  <Modal.Footer>
    <Button onClick={props.onHide}>Close</Button>
  </Modal.Footer>
</Modal>
);

}