import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './NozzleModals.css'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import {NozzleArrayData} from '../../ArrayCreators/NozzleArrayCreator';
import {TankArrayData} from '../../ArrayCreators/TankArrayCreator';
import {TagReaderData} from '../Settings/TagReaders/TagReaders';

export function AddNozzleModal(props) {

  const [state, setState] = useState({ nozzle_number: "", pump_id: props.NozzleAddModalPumpID, tank_id: "", tag_reader_id: "", name: "", tag_authorization: "", description: "" });

  const handleChange = e => {
    let value;
    const name = e.target.name;
    if(e.target.name === "tag_reader_id" && e.target.value === ""){
      setState(prevState => ({...prevState, [name]: null}));
    }
    else if (!isNaN(e.target.value) && e.target.value.length > 0 )
    {value = Number(e.target.value);}
    else {value = e.target.value;}
  
    setState(prevState => ({...prevState, [name]: value}));
  };

  function submit() {

    //e.preventDefault()
    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/nozzle/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': props.token.access_token },
      body: JSON.stringify(state)
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Response not OK.', response);
      }
      return response.json();
    }).then(data => {
      if(data.status_code < 200 || data.status_code > 299)
      { Swal.fire({
        title: 'Error!',
        text: 'Check your input!',
        icon: 'error',
        confirmButtonText: 'Try Again',
        confirmButtonColor: '#F27474'
    
      })}
      else if(data.status_code >= 200 && data.status_code <= 299)
      { Swal.fire({
        title: 'Success!',
        text: 'Added Successfully',
        icon: 'success',
        confirmButtonText: 'Close',
        confirmButtonColor: '#A5DC86'
        
    
      })}
      })
      .catch((e) => {
        Swal.fire({
          title: 'Error!',
          text: 'Connection problem.',
          icon: 'error',
          confirmButtonText: 'Close',
        });
    });
  }

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Add New Nozzle for Pump {props.NozzleAddModalPumpID}.
          </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group controlId="formNozzleNum">
            <Form.Label>Nozzle Number</Form.Label>
            <Form.Control type="number" placeholder="Enter Nozzle Number" value={state.nozzle_number} onChange={handleChange} name="nozzle_number" />
          </Form.Group>

          <Form.Group controlId="formNozzleNam">
            <Form.Label>Nozzle Name</Form.Label>
            <Form.Control type="text" placeholder="Enter Nozzle Name" value={state.name} onChange={handleChange} name="name" />
          </Form.Group>

          <Form.Group controlId="formNozzleNam">
            <Form.Label>Description</Form.Label>
            <Form.Control type="text" placeholder="Enter Nozzle Description" value={state.description} onChange={handleChange} name="description" />
          </Form.Group>

          <Form.Group controlId="formTagStatus">
            <Form.Label>Tag Authorization </Form.Label>
            <Form.Control as="select" type="number"  value={(state.tag_authorization)} onChange={handleChange} name="tag_authorization" >
            <option value = "" disabled>Select Tag Reader Status</option>
            <option value = "1" >Tag Authorization Available</option>
            <option value = "0" >Tag Authorization Not Available</option>
            
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formTagRiD">
            <Form.Label>Tag Reader ID</Form.Label>
            <Form.Control as="select" type="number"  value={(state.tag_reader_id)} onChange={handleChange} name="tag_reader_id" >
            <option value = "" disabled>Select Tag Reader</option>
            <option value = "" >Without a tag reader</option>
            <TagReaderData token={props.token} />
            </Form.Control>
          </Form.Group>
          
          <Form.Group controlId="formTankID">
            <Form.Label>Tank ID</Form.Label>
            <Form.Control as="select" type="number" value={state.tank_id} onChange={handleChange} name="tank_id">
            <option value = "" disabled>Select Tank</option>
            <TankArrayData token={props.token} />
            </Form.Control>
          </Form.Group>
          <br/>
          <Button variant="primary" onClick={submit}>
            Add Nozzle
          </Button>
        </Form>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export function DeleteNozzleModal(props) {

  const [state, setState] = useState({ id: '0' });

  const handleChange = e => {
    let value;
    const name = e.target.name;
    if (!isNaN(e.target.value) && e.target.value.length > 0)
    {value = Number(e.target.value)}
    else {value = e.target.value}
  
    setState(prevState => ({...prevState, [name]: value}));
  };

  function submit() {
    //e.preventDefault()

    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/nozzle/${state.id}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': props.token.access_token },
      body: JSON.stringify(null)
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Response not OK.', response);
      }
      return response.json();
    }).then(data => {
      if(data.status_code < 200 || data.status_code > 299)
      { Swal.fire({
        title: 'Error!',
        text: 'Check your input!',
        icon: 'error',
        confirmButtonText: 'Try Again',
        confirmButtonColor: '#F27474'
    
      })}
      else if(data.status_code >= 200 && data.status_code <= 299)
      { Swal.fire({
        title: 'Success!',
        text: 'Deleted Successfully',
        icon: 'success',
        confirmButtonText: 'Close',
        confirmButtonColor: '#A5DC86'
        
    
      })}
      })
      .catch((e) => {
        Swal.fire({
          title: 'Error!',
          text: 'Connection problem.',
          icon: 'error',
          confirmButtonText: 'Close',
        });
    });
  
  }
  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Existing Nozzle
           </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Form>
          
        <Form.Group controlId="nozzleDeleteDropdown">
            <Form.Label>Select Nozzle to Delete</Form.Label>
            <Form.Control as="select" type="number" value={state.id} onChange={handleChange} name="id">
            <option value = "" disabled>Select Nozzle to Delete.</option>
            < NozzleArrayData token={props.token} nozzlePumpId = {props.NozzleDeleteModalPumpID} LogoutTimeout = {props.LogoutTimeout} />
            </Form.Control>
          </Form.Group>
          <br/>
          <Button variant="danger" type="submit" onClick={submit} key={handleChange}>
            Delete
   </Button>
        </Form>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export function EditNozzleModal({token, LogoutTimeout, show, onHide, NozzleEditModalPumpID}) {
  const [state, setState] = useState({ name: "", description: "", tag_authorization: "", tag_reader_id: "" });
  const [nozzleId, setNozzleId] = useState({id: ""});

  const handleChange = e => {
    let value;
    const name = e.target.name;
    if(e.target.name === "tag_reader_id" && e.target.value === ""){
      setState(prevState => ({...prevState, [name]: null}));
    }
    else if ((!isNaN(e.target.value) && e.target.value.length > 0)  || e.target.name === "tag_reader_id")
    {value = Number(e.target.value)}
    else {value = e.target.value}
  
    setState(prevState => ({...prevState, [name]: value}));
  }

  const handleNozzleChange = e => {
    let value;
    const name = e.target.name;
    if (!isNaN(e.target.value) && e.target.value.length > 0)
    {value = Number(e.target.value)}
    else {value = e.target.value}
  
    setNozzleId(prevState => ({...prevState, [name]: value}));
  }

  /*const [nozzleArray, setnozzleArray] = useState({});

  useEffect(() => {
      fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/nozzle/page?pump_ids=` + props.NozzleEditModalPumpID)
          .then(response => response.json())
          .then(data => setnozzleArray(data.data))
  }, [props.NozzleEditModalPumpID]);*/

  function submit() {
    //e.preventDefault()
    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/nozzle/${nozzleId.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': token.access_token },
      body: JSON.stringify(state)
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Response not OK.', response);
      }
      return response.json();
    }).then(data => {
      if(data.status_code < 200 || data.status_code > 299)
      { Swal.fire({
        title: 'Error!',
        text: 'Check your input!',
        icon: 'error',
        confirmButtonText: 'Try Again',
        confirmButtonColor: '#F27474'
    
      })}
      else if(data.status_code >= 200 && data.status_code <= 299)
      { Swal.fire({
        title: 'Success!',
        text: 'Edited Successfully',
        icon: 'success',
        confirmButtonText: 'Close',
        confirmButtonColor: '#A5DC86'
        
    
      })}
      })
      .catch((e) => {
        Swal.fire({
          title: 'Error!',
          text: 'Connection problem.',
          icon: 'error',
          confirmButtonText: 'Close',
        });
    });
  }

  const clearState = () => {

    setState({
      name: "",
      description: "",
      tag_authorization: "",
      tag_reader_id: ""
    })
    setNozzleId({id: ""});
  }

  useEffect(() => {


    if(nozzleId.id){
    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/nozzle/`+ nozzleId.id , {
      headers: { 'Authorization': token.access_token },
    }
     )
    .then(response => response.json())
    .then(data => setState({
      name: data.data.name,
      description: data.data.description,
      tag_authorization: data.data.tag_authorization,
      tag_reader_id: data.data.tag_reader_id
    }))
  }

  }, [token.access_token, nozzleId.id]);
  
  return (
    <Modal show={show} onHide={() => { onHide(); clearState();}}>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Existing Nozzle
           </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Form>

        <Form.Group controlId="nozzleEditDropdown">
            <Form.Label>Select Existing Nozzle to Edit</Form.Label>
            <Form.Control as="select" type="number" value={nozzleId.id} onChange={handleNozzleChange} name="id">
            <option value = "" disabled>Select Nozzle to Edit.</option>
            < NozzleArrayData token={token} nozzlePumpId = {NozzleEditModalPumpID} LogoutTimeout = {LogoutTimeout} />
            </Form.Control>
          </Form.Group>
          <hr/>

          {/* <Form.Group controlId="formTankID">
            <Form.Label>New Tank</Form.Label>
            <Form.Control as="select" type="text" value={state.tank_id} onChange={handleChange} name="tank_id">
            <option value = "" disabled>Select New Tank.</option>
            <TankArrayData token={token} />
            </Form.Control>
          </Form.Group> */}

          <Form.Group controlId="formNozzleNam">
            <Form.Label>New Nozzle Name</Form.Label>
            <Form.Control type="text" placeholder="Enter New Nozzle Name" value={(state.name)} onChange={handleChange} name="name" />
          </Form.Group>

          <Form.Group controlId="formNozzleDescription">
            <Form.Label>New Nozzle Description</Form.Label>
            <Form.Control type="text" placeholder="Enter New Nozzle Description" value={(state.description)} onChange={handleChange} name="description" />
          </Form.Group>
          
          <Form.Group controlId="formTagStatus">
            <Form.Label>Tag Authorization </Form.Label>
            <Form.Control as="select" type="number"  value={(state.tag_authorization)} onChange={handleChange} name="tag_authorization" >
            <option value = "" disabled>Select Tag Reader Status</option>
            <option value = "1" >Tag Authorization Available</option>
            <option value = "0" >Tag Authorization Not Available</option>
            
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="formTagRiD">
            <Form.Label>Tag Reader ID</Form.Label>
            <Form.Control as="select" type="number"  value={(state.tag_reader_id)} onChange={handleChange} name="tag_reader_id" >
            <option value = "" disabled>Select Tag Reader</option>
            <option value = "" >Without a tag reader</option>
            <TagReaderData token={token} />
            </Form.Control>
          </Form.Group>

          <br/>
          <Button variant="warning" onClick={submit}>
            Edit
   </Button>
        </Form>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => { onHide(); clearState();}}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}