import React, { useState, useEffect } from 'react';
import Badge from 'react-bootstrap/Badge'
import Button from 'react-bootstrap/Button';
import './NozzleCard.css'
import socketIOClient from "socket.io-client";

const ENDPOINT = `http://${sessionStorage.getItem("PumpIp")}/pump_chat`;

//PumpCard Component
export const NozzleCard = (props) => {

  const [amountResponse, setAmountResponse] = useState(0);
  const [volumeResponse, setVolumeResponse] = useState(0);
  const [priceResponse, setPriceResponse] = useState(0);
  const [statusResponse, setStatusResponse] = useState(1);

  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    socket.on('filling_info', data => {
    if(props.nozzlePumpIdCode === data.pump_id )
    {setAmountResponse(data.amount);
     setVolumeResponse(data.volume);
     setPriceResponse(data.price);
    }
     });

     socket.on('nozzle_status', data => {
      if(props.nozzlePumpIdCode === data.pump_id)
    {
      setStatusResponse(data.nozzle_status)
    }
    else if(!props.connectionStatus){
      setStatusResponse(true)
    }
  })

    }, 
    [props.nozzlePumpIdCode, props.connectionStatus]);

  return (
    <div id="NoC" style={statusResponse ? {background: "#7EC8ECAA"} : {background: "orange"}}>

      <div className='flex flex-column'>
          <div className= 'mb1 tl'>
          </div>

          <div className= 'mb1 tl'>
          <Badge pill>Nozzle {props.nozzleOrder}</Badge>
          </div>

      </div>
      <div>

      <div className="nozzleCardSection">
      <Button className= 'nozzleButton' size="sm">
      Unit Price: <Badge variant="light">{priceResponse}TL/L</Badge>
      </Button>
      <Button className= 'nozzleButton' size="sm">
      Volume: <Badge variant="light">{volumeResponse}L</Badge>
      </Button>
      <Button className= 'nozzleButton' size="sm">
      Total: <Badge variant="light">{amountResponse}TL</Badge>
      </Button>
      <Button className= 'nozzleButton' size="sm">
      Product: <Badge variant="light">Gaz 95</Badge>
      </Button>
      </div>
      

      </div>
    </div>
  );
}
