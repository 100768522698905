import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './TankModals.css'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import {TankArrayData, ProductData} from '../../ArrayCreators/TankArrayCreator';


export function AddTankModal(props) {

  const [state, setState] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = e => {
      let value;
      const name = e.target.name;
      if (!isNaN(e.target.value) && e.target.value.length > 0)
      {value = Number(e.target.value);}
      else {value = e.target.value;}

      setState(prevState => ({...prevState, [name]: value}));

      if(!!errors[name]){setErrors({...errors, [name]: null})};
  };

  const clearState = () => {

    setState({})
    setErrors({});
  }

  function submit (){
    //e.preventDefault()
    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/tank/`, {
      method: 'POST', 
      headers: { 'Content-Type': 'application/json', 'Authorization': props.token.access_token },
      body: JSON.stringify(state)
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Response not OK.', response);
      }
      return response.json();
    }).then(data => {
      if(data.status_code === 403)
      { Swal.fire({
        title: 'Error!',
        text: 'You Are Not Authorized',
        icon: 'error',
        confirmButtonText: 'Close', 
      })}
      else if(data.status_code < 200 || data.status_code > 299)
      {
        setErrors(data.data.body.wrong_arguments);
      }
      else if(data.status_code >= 200 && data.status_code <= 299)
      { Swal.fire({
        title: 'Success!',
        text: 'Added Successfully',
        icon: 'success',
        confirmButtonText: 'Close',
        confirmButtonColor: '#A5DC86'  
      })}
      })
      .catch((e) => {
        Swal.fire({
          title: 'Error!',
          text: 'Connection problem.',
          icon: 'error',
          confirmButtonText: 'Close',
        });
    });
    props.setCheckChange(props.checkChange+1);
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {props.onHide(); clearState();}}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Add New Tank
          </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Form>

        <Form.Group controlId="tankNameInput">
            <Form.Label>Name</Form.Label>
            <Form.Control isInvalid={!!errors.name} type="text" placeholder="Enter Name" value={state.name} onChange={handleChange} name="name"/>
            <Form.Control.Feedback type="invalid">{errors.name}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="tankStatusInput">
            <Form.Label>Tank Status</Form.Label>
            <Form.Control isInvalid={!!errors.status} as = "select" type="number" placeholder="Enter Tank Status" value={state.status} onChange={handleChange} name="status">
            <option >Select Status.</option>
            <option value={1} >Active.</option>
            <option value={0} >Inactive.</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.status}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="productIdInput">
            <Form.Label>Product ID</Form.Label>
            <Form.Control isInvalid={!!errors.product_id} as = "select" type="number" placeholder="Enter Product ID" value={state.product_id} onChange={handleChange} name="product_id">
            <option >Select Product.</option>
            <ProductData token={props.token}/>
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.product_id}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="capacityInput">
            <Form.Label>Capacity</Form.Label>
            <Form.Control isInvalid={!!errors.capacity} type="number" placeholder="Enter Capacity/L" value={state.capacity} onChange={handleChange} name="capacity"/>
            <Form.Control.Feedback type="invalid">{errors.capacity}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="descriptionInput">
            <Form.Label>Description</Form.Label>
            <Form.Control isInvalid={!!errors.description} type="text" placeholder="Enter Description" value={state.description} onChange={handleChange} name="description" />
            <Form.Control.Feedback type="invalid">{errors.capacity}</Form.Control.Feedback>
          </Form.Group>
          <br/>
          <Button variant="primary" onClick = {submit}>
            Add
          </Button>
        </Form>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {props.onHide(); clearState();}}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export function DeleteTankModal(props) {
  const [state, setState] = useState({id:""});

  const handleChange = e => {
    let value;
    const name = e.target.name;
    if (!isNaN(e.target.value) && e.target.value.length > 0)
    {value = Number(e.target.value);}
    else {value = e.target.value;}

    setState(prevState => ({...prevState, [name]: value}));
};

  function submit (){
    //e.preventDefault()
    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/tank/${state.id}`, {
      method: 'DELETE', 
      headers: { 'Content-Type': 'application/json', 'Authorization': props.token.access_token },
      body: JSON.stringify(null)
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Response not OK.', response);
      }
      return response.json();
    }).then(data => {
      if(data.status_code < 200 || data.status_code > 299)
      { Swal.fire({
        title: 'Error!',
        text: 'Check your input!',
        icon: 'error',
        confirmButtonText: 'Try Again',
        confirmButtonColor: '#F27474'
    
      })}
      else if(data.status_code >= 200 && data.status_code <= 299)
      { Swal.fire({
        title: 'Success!',
        text: 'Deleted Successfully',
        icon: 'success',
        confirmButtonText: 'Close',
        confirmButtonColor: '#A5DC86'
        
    
      })}
      })
      .catch((e) => {
        Swal.fire({
          title: 'Error!',
          text: 'Connection problem.',
          icon: 'error',
          confirmButtonText: 'Close',
        });
    });
    props.setCheckChange(props.checkChange+1);
    }
  
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Delete Exist Tank
           </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Form>
          <Form.Group controlId="formBasicPassword">
            <Form.Label>Tank ID</Form.Label>
            <Form.Control as= "select" type = "number" placeholder="Select Tank ID" value={state.id} onChange={handleChange} name="id" >
            <option value = "" disabled>Select Tank To Delete.</option>
            <TankArrayData token={props.token}/>
            </Form.Control>
          </Form.Group>
          <br/>
          <Button variant="primary" onClick = {submit}>
            Delete
   </Button>
        </Form>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export function EditTankModal(props) {
  const [state, setState] = useState({ product_id: "", capacity: "", status:"", description: ""});
  const [tankId, setTankId] = useState({id: ""});

  const handleChange = e => {
      let value;
      const name = e.target.name;
      if (!isNaN(e.target.value) && e.target.value.length > 0)
      {value = Number(e.target.value);}
      else {value = e.target.value;}

      setState(prevState => ({...prevState, [name]: value}));
  }
  const handleTankChange = e => {
    
    let value;
    const name = e.target.name;
    if (!isNaN(e.target.value) && e.target.value.length > 0)
    {value = Number(e.target.value)}
    else {value = e.target.value;}
  
    setTankId(prevState => ({...prevState, [name]: value}));

    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/tank/` + e.target.value, {
      headers: { 'Authorization': props.token.access_token },
    })
    .then(response => response.json())
    .then(data => setState({
      product_id: data.data.products_info.id,
      capacity: data.data.capacity,
      status: data.data.status ? 1 : 0,
      description: data.data.description
    }))
  }

  const clearState = () => {

    setState({
      product_id: "",
      capacity: "",
      status:"",
      description: ""
    })
  }
      function submit (){
        //e.preventDefault()
        fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/tank/${tankId.id}`, {
          method: 'PUT', 
          headers: { 'Content-Type': 'application/json', 'Authorization': props.token.access_token },
          body: JSON.stringify(state)
        })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Response not OK.', response);
          }
          return response.json();
        }).then(data => {
          if(data.status_code < 200 || data.status_code > 299)
          { Swal.fire({
            title: 'Error!',
            text: 'Check your input!',
            icon: 'error',
            confirmButtonText: 'Try Again',
            confirmButtonColor: '#F27474'
        
          })}
          else if(data.status_code >= 200 && data.status_code <= 299)
          { Swal.fire({
            title: 'Success!',
            text: 'Edited Successfully',
            icon: 'success',
            confirmButtonText: 'Close',
            confirmButtonColor: '#A5DC86'   
          })}
          })
          .catch((e) => {
            Swal.fire({
              title: 'Error!',
              text: 'Connection problem.',
              icon: 'error',
              confirmButtonText: 'Close',
            });
        });
        props.setCheckChange(props.checkChange+1);
      }
  
  return (
    <Modal show={props.show} onHide={() => { props.onHide(); clearState();}}>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Existing Tank
           </Modal.Title>
      </Modal.Header>
      <Modal.Body>

      <Form>

      <Form.Group controlId="tankSelectInput">
            <Form.Label>Tank ID</Form.Label>
            <Form.Control as= "select" type="number" placeholder="Select Tank ID" value={tankId.id} onChange={handleTankChange} name="id" >
            <option value="" disabled>Select Tank to Edit.</option>
            <TankArrayData token={props.token} />
            </Form.Control>
          </Form.Group> 
          <br/>

            <Form.Group controlId="tankStatusInput">
            <Form.Label>Tank Status</Form.Label>
            <Form.Control as = "select" type="number" placeholder="Enter Tank Status" value={state.status} onChange={handleChange} name="status">
            <option value="" disabled>Select Status.</option>
            <option value={1} >Active.</option>
            <option value={0} >Inactive.</option>
            </Form.Control>
          </Form.Group>

      <Form.Group controlId="productIdInput">
            <Form.Label>Product ID</Form.Label>
            <Form.Control as="select" type="number" placeholder="Enter Product ID" value={state.product_id} onChange={handleChange} name="product_id">
            <option value="" disabled>Select Product.</option>
            <ProductData token={props.token} />
            </Form.Control>
          </Form.Group>

          <Form.Group controlId="capacityInput">
            <Form.Label>Capacity</Form.Label>
            <Form.Control type="number" placeholder="Enter Capacity/L" value={state.capacity} onChange={handleChange} name="capacity"/>
          </Form.Group>

          {/*<Form.Group controlId="stationBranchIdInput">
            <Form.Label>Branch ID</Form.Label>
            <Form.Control type="number" placeholder="Enter Station Branch ID" value={state.gas_station_branch_id} onChange={handleChange} name="gas_station_branch_id" />
          </Form.Group>*/}

          <Form.Group controlId="descriptionInput">
            <Form.Label>Description</Form.Label>
            <Form.Control type="text" placeholder="Enter Description" value={state.description} onChange={handleChange} name="description" />
          </Form.Group>
          <br/>
          <Button variant="primary" onClick = {submit}>
            Edit
          </Button>
        </Form>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => { props.onHide(); clearState();}}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}