import React, { useState } from 'react';
import { color } from 'd3-color';
import { interpolateRgb } from 'd3-interpolate';
import LiquidFillGauge from 'react-liquid-gauge';
import Badge from 'react-bootstrap/Badge';
import { ProbeArrayModal } from '../../ArrayCreators/ProbeArrayCreator';
import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import './TankGauge.css';

export function TankGauge({token, value, name, product, capacity, description, price, temperature, ProbeID, id}) {

    const [probeArrayModalShow, setProbeArrayModalShow] = useState(false);
    const [fillingOrderModalShow, setFillingOrderModalShow] = useState(false);
    // const [tankInfoArray, setTankInfoArray] = useState(false);

    let startColor = '#dc143c'; // crimson
    let endColor = '#6495ed'; // cornflowerblue
 
        const radius = 100;
        const interpolate = interpolateRgb(startColor, endColor);
        const fillColor = interpolate(ProbeID ? value : 100  / 100);
        const gradientStops = [
            {
                key: '0%',
                stopColor: color(fillColor).darker(0.5).toString(),
                stopOpacity: 1,
                offset: '0%'
            },
            {
                key: '50%',
                stopColor: fillColor,
                stopOpacity: 0.75,
                offset: '50%'
            },
            {
                key: '100%',
                stopColor: color(fillColor).brighter(0.5).toString(),
                stopOpacity: 0.5,
                offset: '100%'
            }
        ];

        // useEffect(() => {
        //     const abortController = new AbortController();
        
        //     fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/tank/get_tanks_stats`, {
        //       headers: { Authorization: JSON.parse(sessionStorage.getItem('token')).data.access_token },
        //       signal: abortController.signal,
        //     })
        //     .then((response) => {
        //       if (!response.ok) {
        //         throw new Error("Response not OK.", response);
        //       }
        //       return response.json();
        //     })
        //       .then((data) => setTankInfoArray(data.data.page_records))
        //       .catch((e) => {
        //         Swal.fire({
        //           title: "Error!",
        //           text: "Connection problem.",
        //           icon: "error",
        //           confirmButtonText: "Close",
        //         });
        //       });
        
        //       return () => {abortController.abort();}
                
        //   }, []);
 
        return (
            <>
            <FillingOrderModal
            tankId={id}
            token={token}
            show={fillingOrderModalShow}
            onHide={() => setFillingOrderModalShow(false)}
            />

            <ProbeArrayModal
            token = {token}
            show={probeArrayModalShow}
            onHide={() => setProbeArrayModalShow(false)}
            ProbeID={ProbeID}
            tankName={name}
            />

            <div id="LiquidGauge">
                <LiquidFillGauge
                    style={{ margin: '0 auto'}}
                    width={radius * 2}
                    height={radius * 2}
                    value={ProbeID ? value : 100}
                    percent="%"
                    textSize={1}
                    textOffsetX={0}
                    textOffsetY={0}
                    textRenderer={(props) => {
                        const value = Math.round(props.value);
                        const radius = Math.min(props.height / 2, props.width / 2);
                        const textPixels = (props.textSize * radius / 2);
                        const valueStyle = {
                            fontSize: textPixels
                        };
                        const percentStyle = {
                            fontSize: textPixels * 0.6
                        };
 
                        return (
                            <tspan>
                                <tspan className="value" style={valueStyle}>{value}</tspan>
                                <tspan style={percentStyle}>{props.percent}</tspan>
                            </tspan>
                        );
                    }}
                    riseAnimation
                    waveAnimation
                    waveFrequency={2}
                    waveAmplitude={1}
                    gradient
                    gradientStops={gradientStops}
                    circleStyle={{
                        fill: fillColor
                    }}
                    waveStyle={{
                        fill: fillColor
                    }}
                    textStyle={{
                        fill: color('#444').toString(),
                        fontFamily: 'Arial'
                    }}
                    waveTextStyle={{
                        fill: color('#fff').toString(),
                        fontFamily: 'Arial'
                    }}
                />
                <div
                    style={{
                        margin: '20px auto',
                        width: 120
                    }}
                >
                   
                </div>
                {/* <div className='PuCInfo' id="NozzleButton" onClick={() => setNozzleArrayModalShow(true)}> */}
                {/* <Badge className={(status) ? "TankStatusBadgeActive" : "TankStatusBadgeInactive"}>Tank Status: {(status) ? "Active" : "Inactive"}</Badge> */}
                <Badge className={(ProbeID) ? "TankStatusBadgeActive" : "TankStatusBadgeInactive"} id="ProbeButton" onClick={() => setProbeArrayModalShow(true)}>Probe: {(ProbeID) ? "Available" : "Not defined"}</Badge>
                <Badge className="TankBadge" id="FillingButton" onClick={() => setFillingOrderModalShow(true)}>Tank Filling Order</Badge>  
                <Badge className="TankBadge" >Tank Name: {name}</Badge>
                <Badge className="TankBadge" >Fuel Type: {product}</Badge>
                <Badge className="TankBadge" >Fuel Capacity: {capacity} Liters</Badge>
                {ProbeID ? <Badge className="TankBadge" >Current Volume: {value} Liters</Badge> : null}
                {ProbeID ? <Badge className="TankBadge" >Current Temperature: {temperature/10}°C</Badge> : null}
                <Badge className="TankBadge" >Unit Price: {price}</Badge>
                <Badge className="TankBadge" >Tank Description: {description}</Badge>
            </div>
            </>
        );         
}    

export function FillingOrderModal({tankId, show, onHide, token}) {

    const [state, setState] = useState({
      tank_id: tankId,
      litre_price: "",
      amount: "",
      supplier: "",
  });
  
    const handleChange = e => {
      let value;
      const name = e.target.name;
      if(e.target.name === "tag_reader_id" && e.target.value === ""){
        setState(prevState => ({...prevState, [name]: null}));
      }
      else if (!isNaN(e.target.value) && e.target.value.length > 0 )
      {value = Number(e.target.value);}
      else {value = e.target.value;}
    
      setState(prevState => ({...prevState, [name]: value}));
    };
  
    function submit() {
  
      //e.preventDefault()
      fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/tanks_filling_archive/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': token.access_token },
        body: JSON.stringify(state)
      })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Response not OK.', response);
        }
        return response.json();
      }).then(data => {
        if(data.status_code < 200 || data.status_code > 299)
        { Swal.fire({
          title: 'Error!',
          text: 'Check your input!',
          icon: 'error',
          confirmButtonText: 'Try Again',
          confirmButtonColor: '#F27474'
      
        })}
        else if(data.status_code >= 200 && data.status_code <= 299)
        { Swal.fire({
          title: 'Success!',
          text: 'Processed Successfully',
          icon: 'success',
          confirmButtonText: 'Close',
          confirmButtonColor: '#A5DC86'
          
      
        })}
        })
        .catch((e) => {
          Swal.fire({
            title: 'Error!',
            text: 'Connection problem.',
            icon: 'error',
            confirmButtonText: 'Close',
          });
      });
    }
  
    return (
      <Modal show={show} onHide={onHide}>
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Filling Order Info
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>

          <p><b>*Use this form only <i>after</i> filling is complete.</b></p>

          <Form.Group controlId="formNozzleNum">
              <Form.Label>Supplier Name</Form.Label>
              <Form.Control type="text" placeholder="Enter Supplier Name" value={state.supplier} onChange={handleChange} name="supplier" />
            </Form.Group>
  
            <Form.Group controlId="formNozzleNum">
              <Form.Label>Fuel Amount</Form.Label>
              <Form.Control type="number" placeholder="Enter Fuel Amount" value={state.amount} onChange={handleChange} name="amount" />
            </Form.Group>

            <Form.Group controlId="formNozzleNum">
              <Form.Label>Liter Price</Form.Label>
              <Form.Control type="number" placeholder="Enter Liter Price" value={state.litre_price} onChange={handleChange} name="litre_price" />
            </Form.Group>
  
            <br/>
  
            <Button variant="primary" onClick={submit}>
              Process
            </Button>
          </Form>
  
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }