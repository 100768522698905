import React, { useState, useEffect } from 'react';
import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import './ProbeModals.css'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import {TankArrayData} from '../../ArrayCreators/TankArrayCreator';

export function AddProbeModal(props) {

  const [state, setState] = useState({
    name: "",
    product_H: "",
    product_HH: "",
    product_L: "",
    product_LL: "",
    water_H: "",
    water_L: "",
    temperature_H: "",
    temperature_L: "",
    probe_identification_code: "",
    channel: "",
    baud: "",
    probe_address: "",
    tank_id: "",
});

  const handleChange = e => {
    let value;
    const name = e.target.name;

    if (!isNaN(e.target.value) && e.target.value.length > 0 && e.target.name !== "probe_identification_code")
    {value = Number(e.target.value);}
    else {value = e.target.value;}
  
    setState(prevState => ({...prevState, [name]: value}));
  };

  function submit() {

    //e.preventDefault()
    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/probe/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': props.token.access_token },
      body: JSON.stringify(state)
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Response not OK.', response);
      }
      return response.json();
    }).then(data => {
      if(data.status_code < 200 || data.status_code > 299)
      { Swal.fire({
        title: 'Error!',
        text: 'Check your input!',
        icon: 'error',
        confirmButtonText: 'Try Again',
        confirmButtonColor: '#F27474'
    
      })}
      else if(data.status_code >= 200 && data.status_code <= 299)
      { Swal.fire({
        title: 'Success!',
        text: 'Added Successfully',
        icon: 'success',
        confirmButtonText: 'Close',
        confirmButtonColor: '#A5DC86'
        
    
      })}
      })
      .catch((e) => {
        Swal.fire({
          title: 'Error!',
          text: 'Connection problem.',
          icon: 'error',
          confirmButtonText: 'Close',
        });
    });
  }

  return (
    <Modal show={props.show} onHide={props.onHide}>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Add New Probe for This Tank
          </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
        <Form.Group controlId="formNozzleNum">
            <Form.Label>Probe Name</Form.Label>
            <Form.Control type="text" placeholder="Enter Probe Name" value={state.name} onChange={handleChange} name="name" />
          </Form.Group>

          <Form.Group controlId="formNozzleNum">
            <Form.Label>Dangerous Fuel Level (Upper)</Form.Label>
            <Form.Control type="number" placeholder="Enter Maximum Level" value={state.product_HH} onChange={handleChange} name="product_HH" />
          </Form.Group>

          <Form.Group controlId="formNozzleNam">
            <Form.Label>Maximum Fuel Level (Upper)</Form.Label>
            <Form.Control type="number" placeholder="Enter Upper Level" value={state.product_H} onChange={handleChange} name="product_H" />
          </Form.Group>

          <Form.Group controlId="formNozzleNam">
            <Form.Label>Minimum Fuel Level (Lower)</Form.Label>
            <Form.Control type="number" placeholder="Enter Minimum Level" value={state.product_L} onChange={handleChange} name="product_L" />
          </Form.Group>
          
          <Form.Group controlId="formNozzleNam">
            <Form.Label>Dangerous Fuel Level (Lower)</Form.Label>
            <Form.Control type="number" placeholder="Enter Lower Level" value={state.product_LL} onChange={handleChange} name="product_LL" />
          </Form.Group>

          <Form.Group controlId="formNozzleNam">
            <Form.Label>Dangerous Water Level (Upper)</Form.Label>
            <Form.Control type="number" placeholder="Enter Upper Level" value={state.water_H} onChange={handleChange} name="water_H" />
          </Form.Group>

          <Form.Group controlId="formNozzleNum">
            <Form.Label>Dangerous Water Level (Lower)</Form.Label>
            <Form.Control type="number" placeholder="Enter Lower Level" value={state.water_L} onChange={handleChange} name="water_L" />
          </Form.Group>

          <Form.Group controlId="formNozzleNam">
            <Form.Label>Maximum Temperature</Form.Label>
            <Form.Control type="number" placeholder="Enter Maximum Temperature" value={state.temperature_H} onChange={handleChange} name="temperature_H" />
          </Form.Group>

          <Form.Group controlId="formNozzleNam">
            <Form.Label>Minimum Temperature</Form.Label>
            <Form.Control type="number" placeholder="Enter Minimum Temperature" value={state.temperature_L} onChange={handleChange} name="temperature_L" />
          </Form.Group>

          <Form.Group controlId="formNozzleNam">
            <Form.Label>Probe Identification Code</Form.Label>
            <Form.Control type="text" placeholder="Enter Identification Code" value={state.probe_identification_code} onChange={handleChange} name="probe_identification_code" />
          </Form.Group>

          <Form.Group controlId="formNozzleNam">
            <Form.Label>Channel</Form.Label>
            <Form.Control type="number" placeholder="Enter Channel" value={state.channel} onChange={handleChange} name="channel" />
          </Form.Group>

          <Form.Group controlId="formNozzleNam">
            <Form.Label>Baud</Form.Label>
            <Form.Control type="number" placeholder="Enter Baud" value={state.baud} onChange={handleChange} name="baud" />
          </Form.Group>

          <Form.Group controlId="formNozzleNam">
            <Form.Label>Probe Address</Form.Label>
            <Form.Control type="number" placeholder="Enter Address" value={state.probe_address} onChange={handleChange} name="probe_address" />
          </Form.Group>
          
          <Form.Group controlId="formTankID">
            <Form.Label>Tank ID</Form.Label>
            <Form.Control as="select" type="number" value={state.tank_id} onChange={handleChange} name="tank_id">
            <option value = "" disabled>Select Tank</option>
            <TankArrayData token={props.token} />
            </Form.Control>
          </Form.Group>

          <br/>

          <Button variant="primary" onClick={submit}>
            Add
          </Button>
        </Form>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}

export function EditProbeModal({token, show, onHide, ProbeID}) {

  const [state, setState] = useState({
    name: "",
    product_H: "",
    product_HH: "",
    product_L: "",
    product_LL: "",
    water_H: "",
    water_L: "",
    temperature_H: "",
    temperature_L: "",
    tank_id: "",
});

  const handleChange = e => {
    let value;
    const name = e.target.name;

    if (!isNaN(e.target.value) && e.target.value.length > 0)
    {value = Number(e.target.value)}
    else {value = e.target.value}
  
    setState(prevState => ({...prevState, [name]: value}));
  }

  function submit() {
    //e.preventDefault()
    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/probe/${ProbeID}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': token.access_token },
      body: JSON.stringify(state)
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Response not OK.', response);
      }
      return response.json();
    }).then(data => {
      if(data.status_code < 200 || data.status_code > 299)
      { Swal.fire({
        title: 'Error!',
        text: 'Check your input!',
        icon: 'error',
        confirmButtonText: 'Try Again',
        confirmButtonColor: '#F27474'  
      })}
      else if(data.status_code >= 200 && data.status_code <= 299)
      { Swal.fire({
        title: 'Success!',
        text: 'Edited Successfully',
        icon: 'success',
        confirmButtonText: 'Close',
        confirmButtonColor: '#A5DC86'   
      })}
      })
      .catch((e) => {
        Swal.fire({
          title: 'Error!',
          text: 'Connection problem.',
          icon: 'error',
          confirmButtonText: 'Close',
        });
    });
  }

  // const clearState = () => {

    // setState({
    // name: "",
    // product_H: "",
    // product_HH: "",
    // product_L: "",
    // product_LL: "",
    // water_H: "",
    // water_L: "",
    // temperature_H: "",
    // temperature_L: "",
    // probe_identification_code: "",
    // channel: "",
    // baud: "",
    // probe_address: "",
    // tank_id: "",
    // })
  // }

  useEffect(() => {

    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/probe/`+ ProbeID , {
      headers: { 'Authorization': token.access_token },
    }
     )
    .then(response => response.json())
    .then(data =>    setState({
      name: data.data.name,
      product_H: data.data.product_H,
      product_HH: data.data.product_HH,
      product_L: data.data.product_L,
      product_LL: data.data.product_LL,
      water_H: data.data.water_H,
      water_L: data.data.water_L,
      temperature_H: data.data.temperature_H,
      temperature_L: data.data.temperature_L,
      tank_id: data.data.tank_id,
      }))

  }, [token.access_token, ProbeID]);
  
  return (
    <Modal show={show} onHide={() => { onHide();}}>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Edit Existing Probe
           </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
        <Form.Group controlId="formNozzleNum">
            <Form.Label>Probe Name</Form.Label>
            <Form.Control type="text" placeholder="Enter Probe Name" value={state.name} onChange={handleChange} name="name" />
          </Form.Group>

          <Form.Group controlId="formNozzleNum">
            <Form.Label>Dangerous Fuel Level (Upper)</Form.Label>
            <Form.Control type="number" placeholder="Enter Maximum Level" value={state.product_HH} onChange={handleChange} name="product_HH" />
          </Form.Group>

          <Form.Group controlId="formNozzleNam">
            <Form.Label>Maximum Fuel Level (Upper))</Form.Label>
            <Form.Control type="number" placeholder="Enter Upper Level" value={state.product_H} onChange={handleChange} name="product_H" />
          </Form.Group>

          <Form.Group controlId="formNozzleNam">
            <Form.Label>Minimum Fuel Level (Lower)</Form.Label>
            <Form.Control type="number" placeholder="Enter Minimum Level" value={state.product_L} onChange={handleChange} name="product_L" />
          </Form.Group>
          
          <Form.Group controlId="formNozzleNam">
            <Form.Label>Dangerous Fuel Level (Lower)</Form.Label>
            <Form.Control type="number" placeholder="Enter Lower Level" value={state.product_LL} onChange={handleChange} name="product_LL" />
          </Form.Group>

          <Form.Group controlId="formNozzleNam">
            <Form.Label>Dangerous Water Level (Upper)</Form.Label>
            <Form.Control type="number" placeholder="Enter Upper Level" value={state.water_H} onChange={handleChange} name="water_H" />
          </Form.Group>

          <Form.Group controlId="formNozzleNum">
            <Form.Label>Dangerous Water Level (Lower)</Form.Label>
            <Form.Control type="number" placeholder="Enter Lower Level" value={state.water_L} onChange={handleChange} name="water_L" />
          </Form.Group>

          <Form.Group controlId="formNozzleNam">
            <Form.Label>Maximum Temperature</Form.Label>
            <Form.Control type="number" placeholder="Enter Maximum Temperature" value={state.temperature_H} onChange={handleChange} name="temperature_H" />
          </Form.Group>

          <Form.Group controlId="formNozzleNam">
            <Form.Label>Minimum Temperature</Form.Label>
            <Form.Control type="number" placeholder="Enter Minimum Temperature" value={state.temperature_L} onChange={handleChange} name="temperature_L" />
          </Form.Group>

          {/* <Form.Group controlId="formNozzleNam">
            <Form.Label>Probe Identification Code</Form.Label>
            <Form.Control type="number" placeholder="Enter Identification Code" value={state.probe_identification_code} onChange={handleChange} name="probe_identification_code" />
          </Form.Group>

          <Form.Group controlId="formNozzleNam">
            <Form.Label>Channel</Form.Label>
            <Form.Control type="number" placeholder="Enter Channel" value={state.channel} onChange={handleChange} name="channel" />
          </Form.Group>

          <Form.Group controlId="formNozzleNam">
            <Form.Label>Baud</Form.Label>
            <Form.Control type="number" placeholder="Enter Baud" value={state.baud} onChange={handleChange} name="baud" />
          </Form.Group>

          <Form.Group controlId="formNozzleNam">
            <Form.Label>Probe Address</Form.Label>
            <Form.Control type="number" placeholder="Enter Address" value={state.probe_address} onChange={handleChange} name="probe_address" />
          </Form.Group> */}
          
          <Form.Group controlId="formTankID">
            <Form.Label>Tank ID</Form.Label>
            <Form.Control as="select" type="number" value={state.tank_id} onChange={handleChange} name="tank_id">
            <option value = "" disabled>Select Tank</option>
            <TankArrayData token={token} />
            </Form.Control>
          </Form.Group>

          <br/>
          <Button variant="warning" onClick={submit}>
            Edit
   </Button>
        </Form>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => { onHide();}}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}