import React, { useState, useEffect} from 'react';
import { Modal } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import './AddCustomers.css';

export function AddCustomers ({show, onHide, checkChange, setCheckChange}) {

  // fleet_name: "", first_name: "", last_name:"", email: "",  suspended: "", limit_amount: "", is_auto_refill: "", limit_type_id: "", limit_category_id: ""

const [state, setState] = useState({});
let regEmail = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const [limitTypeData, setLimitTypeData] = useState([]);
const [limitPeriod, setLimitPeriod] = useState([]);
const [errors, setErrors] = useState({});

const handleChange = e => {
  let value;
  const name = e.target.name;
  if (!isNaN(e.target.value) && e.target.value.length > 0)
  {value = Number(e.target.value);}
  else {value = e.target.value;}

  setState(prevState => ({...prevState, [name]: value}));

  if(!!errors[name]){setErrors({...errors, [name]: null})};
};

// const validate = ()=>{
//   const {fleet_name, first_name, last_name, email,  suspended, limit_amount, is_auto_refill, limit_type_id , limit_category_id} = state
//   const newErrors = {}

//   if(!fleet_name || fleet_name === ""){newErrors.fleet_name = "Fleet name cannot be empty."}
//   else if(!isNaN(fleet_name)){newErrors.fleet_name = "Fleet name cannot be a number."}
  
//   if(!first_name || first_name === ""){newErrors.first_name = "First name cannot be empty."}
//   else if(!isNaN(first_name)){newErrors.first_name = "First name cannot be a number."}

//   if(!last_name || last_name === ""){newErrors.last_name = "Last name cannot be empty."}
//   else if(!isNaN(last_name)){newErrors.last_name = "Last name cannot be a number."}

//   if(!fleet_name || fleet_name === ""){newErrors.fleet_name = "Fleet name cannot be empty."}
//   else if(!isNaN(fleet_name)){newErrors.fleet_name = "Fleet name cannot be a number."}

//   return newErrors;
// }


// fleet_name: "",
// first_name: "",
// last_name:"",
// email: "",
// suspended: "",
// limit_amount: "",
// is_auto_refill: "",
// limit_type_id: "",
// limit_category_id: ""

const clearState = () => {

    setState({});
    setErrors({});
  }

  function submit() {
    //e.preventDefault()

    // const fieldErrors = validate();
    
    // if(Object.keys(fieldErrors).length > 0){
    //   setErrors(fieldErrors);
    // }

if (!regEmail.test(state.email) && !!state.email) {
  setErrors({...errors, email: "Invalid email address!"})
}
    else {
    fetch(`http://${sessionStorage.getItem("ReaderIp")}/v1/fleets/`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token },
      body: JSON.stringify(state)
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Response not OK.', response);
      }
      return response.json();
    })
    .then(data => {
      if(data.status_code === 403)
      { Swal.fire({
        title: 'Error!',
        text: 'You Are Not Authorized',
        icon: 'error',
        confirmButtonText: 'Close',
      })}
      else if(data.status_code < 200 || (data.status_code >= 299))
      {
        setErrors(data.data.body.wrong_arguments);
      }
      else if(data.status_code >= 200 && data.status_code <= 299)
      { Swal.fire({
        title: 'Success!',
        text: 'Added Successfully',
        icon: 'success',
        confirmButtonText: 'Close',
        confirmButtonColor: '#A5DC86'
      })
      setCheckChange(checkChange+1);
    }
      })
      .catch((e) => {
        Swal.fire({
          title: 'Error!',
          text: 'Connection problem.',
          icon: 'error',
          confirmButtonText: 'Close',
        });
    });}
  }

  useEffect(() => {
    const abortController = new AbortController();

if(show){
      fetch(`http://${sessionStorage.getItem("ReaderIp")}/v1/limit_types/page`, {
      headers: { Authorization: JSON.parse(sessionStorage.getItem('token')).data.access_token },
      signal: abortController.signal,
    })
      .then((response) => response.json())
      .then((data) => setLimitTypeData(data.data.page_records));

      fetch(`http://${sessionStorage.getItem("ReaderIp")}/v1/limit_categories/page`, {
      headers: { Authorization: JSON.parse(sessionStorage.getItem('token')).data.access_token },
      signal: abortController.signal,
    })
      .then((response) => response.json())
      .then((data) => setLimitPeriod(data.data.page_records));
  }
      return () => {abortController.abort();}
      
  }, [show]);

  return (
    <Modal show={show} onHide={() => {onHide(); clearState();}}>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Add New Customers/Fleet
          </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <Form>
          <h6>Please use real first name, last name, and email.</h6>  
          <Form.Group controlId="formUserUsername">
            <Form.Label>Fleet Name</Form.Label>
            <Form.Control isInvalid={!!errors.fleet_name} type="text" placeholder="Enter Fleet Name" value={state.fleet_name} onChange={handleChange} name="fleet_name" />
            <Form.Control.Feedback type="invalid">{errors.fleet_name}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formUserFirstName">
            <Form.Label>First Name</Form.Label>
            <Form.Control isInvalid={!!errors.first_name} type="text" placeholder="Enter First Name" value={state.first_name} onChange={handleChange} name="first_name" />
            <Form.Control.Feedback type="invalid">{errors.first_name}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formUserLastName">
            <Form.Label>Last Name</Form.Label>
            <Form.Control isInvalid={!!errors.last_name} type="text" placeholder="Enter Last Name" value={state.last_name} onChange={handleChange} name="last_name" />
            <Form.Control.Feedback type="invalid">{errors.last_name}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formUserEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control isInvalid={!!errors.email} type="email" placeholder="Enter Email" value={state.email} onChange={handleChange} name="email" />
            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
          </Form.Group>
          
          {/* <Form.Group controlId="formUserPassword">
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" placeholder="Enter Password" value={state.password} onChange={handleChange} name="password" />
          </Form.Group> */}

          <Form.Group controlId="formUserLastName">
            <Form.Label>Limit Amount</Form.Label>
            <Form.Control isInvalid={!!errors.limit_amount} type="number" placeholder="Enter Limit Amount" value={state.limit_amount} onChange={handleChange} name="limit_amount" />
            <Form.Control.Feedback type="invalid">{errors.limit_amount}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formCustomerRefill">
          <Form.Label>Auto Refill</Form.Label>
            <Form.Control isInvalid={!!errors.is_auto_refill} as="select" type="number" value={state.is_auto_refill} onChange={handleChange} name="is_auto_refill">
            <option >Auto Refill?</option>
            <option value={0}>No</option>
            <option value={1}>Yes</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.is_auto_refill}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formCustomerLimitTypes">
            <Form.Label>Limit Type</Form.Label>
            <Form.Control isInvalid={!!errors.limit_type_id} as="select" type="number" value={state.limit_type_id} onChange={handleChange} name="limit_type_id">
            <option >Select Limit Type</option>
            {limitTypeData.map((item, index) => (
                  <option key={index} value={item.id}>
                    {" "}
                    {item.name}
                  </option>
                ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.limit_type_id}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formCustomerLimitPeriod">
            <Form.Label>Recurrence Limit</Form.Label>
            <Form.Control isInvalid={!!errors.limit_category_id} as="select" type="number" value={state.limit_category_id} onChange={handleChange} name="limit_category_id">
            <option >Select Recurrence Limit</option>
            {limitPeriod.map((item, index) => (
                  <option key={index} value={item.id}>
                    {" "}
                    {item.name}
                  </option>
                ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.limit_category_id}</Form.Control.Feedback>
          </Form.Group>

          {/* <Form.Group controlId="formRfidCardId">
          <Form.Label>RFID Card ID</Form.Label>
        <FormControl>
        <TextField
          id="standard-helperText-id"
          name="card_id"
          label="ID"
          value={RFIDCardsState.card_id}
          onChange={handleChange}
        />
        </FormControl>
        </Form.Group> */}

          <Form.Group controlId="formUserRole">
            <Form.Label>Status</Form.Label>
            <Form.Control isInvalid={!!errors.suspended} as="select" type="number" value={state.suspended} onChange={handleChange} name="suspended">
            <option >Select Status</option>
            <option value={0}>Active</option>
            <option value={1}>Inactive</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">{errors.suspended}</Form.Control.Feedback>
          </Form.Group>
          <br/>
          <Button variant="primary" onClick={() => { submit();}}>
            Add
          </Button>
        </Form>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {onHide(); clearState();}}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}