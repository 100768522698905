import React from "react";
import './Footer.css'
import Clock from 'react-live-clock';
import { useLocation } from 'react-router-dom';
// import Ticker from 'react-ticker';

const Footer = ({token, setViewMenusState, viewMenusState}) => {

  const location = useLocation();

  function toggleFullScreen() {
    if (!document.fullscreenElement) {
        document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  }

  const role =
    (token?.user_record.roles.id === 1) ? '"Account Manager"'
  : (token?.user_record.roles.id === 2) ? '"Branch Manager"'
  : (token?.user_record.roles.id === 3) ? '"Technician"'
  : (token?.user_record.roles.id === 4) ? '"Manager"'
  : (token?.user_record.roles.id === 5) ? '"Operator"' : "Not logged in.";

  return(
<>
{/* <div id="ticker">
<Ticker>
        {({ index }) => (
            <>
                <h1>This is the Headline of element #{index}!</h1>
                <img src="www.my-image-source.com/" alt=""/>
            </>
        )}
    </Ticker>
</div> */}
  <div className="footer">
    <div id="clockDiv">
  <Clock
          format={'dddd, DD/MMMM/YYYY, h:mm:ss A'}
          ticking={true} />
          </div>
  <div id="logoutDiv">
  <p id="logoutText">Logged in as: {role}</p>
  <p id="versionText"><b>v</b>0.9.22</p>
  <button className="fas fa-desktop" id="hideMenuButton" onClick={() => {toggleFullScreen()}} title="Toggle fullscreen"></button>
  <button className="fas fa-eye" hidden={(location.pathname === '/PumpCard' || location.pathname === '/') ? false : true} id="hideMenuButton" onClick={() => {setViewMenusState(viewMenusState ? false : true)}} title="Hide/View menus"></button>
  <button hidden={token ? false : true} id="logoutButton" className="fas fa-sign-out-alt" title="Logout" onClick={() => {
    fetch(`http://${sessionStorage.getItem("ReaderIp")}/v1/authentication/logout`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': token.access_token },
      body: JSON.stringify(null)
    });
    sessionStorage.clear();
    window.location.reload(false);}}></button>
  </div>
  </div>
  
  </>
  );
  }

export default Footer;