import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Alert, AlertTitle } from "@material-ui/lab";
import Swal from "sweetalert2/dist/sweetalert2.js";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(0),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  gridItem: {
    padding: 10,
    margin: 10,
  },
}));

export default function AlarmsArrayCreator({ LogoutTimeout, token }) {
  const classes = useStyles();
  const [AlarmData, setAlarmData] = useState([]);
  const [ErrorData, setErrorData] = useState([]);

  useEffect(() => {
    const abortController = new AbortController();

    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/alarms/page`, {
      headers: { 'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token },
      signal: abortController.signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Response not OK.", response);
        }
        return response.json();
      })
      .then((data) => setAlarmData(data.data.page_records))
      .catch((e) => {
        Swal.fire({
          title: "Error!",
          text: "Connection problem.",
          icon: "error",
          confirmButtonText: "Close",
        });
      });

    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/errors/page`, {
      headers: { 'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token },
      signal: abortController.signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Response not OK.", response);
        }
        return response.json();
      })
      .then((data) => setErrorData(data.data.page_records))
      .catch((e) => {
        Swal.fire({
          title: "Error!",
          text: "Connection problem.",
          icon: "error",
          confirmButtonText: "Close",
        });
      });

      return () => {abortController.abort();}
      
  }, []);

  

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6}>
          <Alert className={classes.gridItem} severity="warning">
            <AlertTitle>Warning</AlertTitle>
            This is a warning alert — <strong>check it out!</strong>
          </Alert>
          <Alert className={classes.gridItem} severity="warning">
            <AlertTitle>Warning</AlertTitle>
            This is a warning alert — <strong>check it out!</strong>
          </Alert>
          <Alert className={classes.gridItem} severity="warning">
            <AlertTitle>Warning</AlertTitle>
            This is a warning alert — <strong>check it out!</strong>
          </Alert>
          {AlarmData.map((item, index) => (
            <Alert key={index} className={classes.gridItem} severity="warning">
              <AlertTitle>Warning</AlertTitle>
              This is a warning alert from Pump {item.pump_ids} with code{" "}
              {item.alarm_codes} — <strong>check it out!</strong>
            </Alert>
          ))}
        </Grid>

        <Grid item xs={12} sm={6}>
          <Alert className={classes.gridItem} severity="error">
            <AlertTitle>Error</AlertTitle>
            This is an error alert — <strong>check it out!</strong>
          </Alert>
          <Alert className={classes.gridItem} severity="error">
            <AlertTitle>Error</AlertTitle>
            This is an error alert — <strong>check it out!</strong>
          </Alert>
          <Alert className={classes.gridItem} severity="error">
            <AlertTitle>Error</AlertTitle>
            This is an error alert — <strong>check it out!</strong>
          </Alert>
          {ErrorData.map((item, index) => (
            <Alert key={index} className={classes.gridItem} severity="error">
              <AlertTitle>Error</AlertTitle>
              This is an error alert of type {item.names} —{" "}
              <strong>check it out!</strong>
            </Alert>
          ))}
        </Grid>
      </Grid>
    </div>
  );
}
