import React, { useState, useEffect } from "react";
import "./Reports.css";
import { Pagination } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { utils, writeFile } from "xlsx";
import traIcon from '../media/icons/logo.png';


//Styles for filter input fields.
const useStyles = makeStyles((theme) => ({
  formControl: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  FilterClearButtonClass: {
    float: 'right',
    margin: 10,
  },
}));

//Reports component includes filter input fields.
export function Reports({toggleButton, checkboxesView, token}) {
  //Filter input fields state.
  const classes = useStyles();
  const [state, setState] = useState({
    current_page: 1,
    station_serials: "",
    pump_names: "",
    nozzle_names: "",
    since_date: "",
    to_date: "",
    car_plates: "",
    product_names: "",
    tank_names: "",
    card_ids: "",
    tag_ids: "",
  });
  const [reportData, setReportData] = useState([]);
  const [currentPageState, setCurrentPageState] = useState({});
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [stationsArray, setStationsArray] = useState([]);
  const [pumpsArray, setPumpsArray] = useState([]);
  const [nozzleArrayData, setNozzleArrayData] = useState([]);
  const [filterTankArrayData, setFilterTankArrayData] = useState([]);
  const [filterProductData, setFilterProductData] = useState([]);
  const [checkedNozzle, setCheckedNozzle] = useState(false);
  const [checkedFuel, setCheckedFuel] = useState(false);
  const [checkedTank, setCheckedTank] = useState(false);
  const [checkedAmount, setCheckedAmount] = useState(true);
  const [checkedPrice, setCheckedPrice] = useState(false);
  const [checkedPlate, setCheckedPlate] = useState(false);
  const [checkedSalesId, setCheckedSalesId] = useState(false);
  const [checkedReceiptId, setCheckedReceiptId] = useState(false);
  const [checkedStation, setCheckedStation] = useState(true);

  const handleCheckboxChangeNozzle = (event) => {
    setCheckedNozzle(event.target.checked);
  };

  const handleCheckboxChangeFuel = (event) => {
    setCheckedFuel(event.target.checked);
  };

  const handleCheckboxChangeTank = (event) => {
    setCheckedTank(event.target.checked);
  };

  // const handleCheckboxChangeVolume = (event) => {
  //   setCheckedVolume(event.target.checked);
  // };

  const handleCheckboxChangeAmount = (event) => {
    setCheckedAmount(event.target.checked);
  };

  const handleCheckboxChangePrice = (event) => {
    setCheckedPrice(event.target.checked);
  };

  const handleCheckboxChangePlate = (event) => {
    setCheckedPlate(event.target.checked);
  };

  const handleCheckboxChangeSalesId = (event) => {
    setCheckedSalesId(event.target.checked);
  };

  const handleCheckboxChangeReceiptId = (event) => {
    setCheckedReceiptId(event.target.checked);
  };

  const handleCheckboxChangeStation = (event) => {
    setCheckedStation(event.target.checked);
  };

  let itemNo = 0;

  //Handle change for filter input fields.
  const handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    // Append seconds ":00" if not provided (assuming time format: "hh:mm" or "hh:mm:ss")
  if (e.target.type === "datetime-local" && value.length === 16) {
    value += ":00";
  }

    setState((prevState) => ({ ...prevState, [name]: value }));
    initializePagination();
  };

  const handleStationsChange = (event, value) => {
    let tempQuery = "";
    if(value[0]?.query){
    value.map(item => tempQuery = tempQuery + item.query)}
    setState((prevState) => ({ ...prevState, station_serials: tempQuery }));
    initializePagination();
  };

  const handlePumpChange = (event, value) => {
    let tempQuery = "";
    if(value[0]?.query){
    value.map(item => tempQuery = tempQuery + item.query)}
    setState((prevState) => ({ ...prevState, pump_names: tempQuery }));
    initializePagination();
  };

  const handleNozzleChange = (event, value) => {
    let tempQuery = "";
    if(value[0]?.query){
    value.map(item => tempQuery = tempQuery + item.query)}
    setState((prevState) => ({ ...prevState, nozzle_names: tempQuery }));
    initializePagination();
  };

  const handleTankChange = (event, value) => {
    let tempQuery = "";
    if(value[0]?.query){
    value.map(item => tempQuery = tempQuery + item.query)}
    setState((prevState) => ({ ...prevState, tank_names: tempQuery }));
    initializePagination();
  };

  const handleProductChange = (event, value) => {
    let tempQuery = "";
    if(value[0]?.query){
    value.map(item => tempQuery = tempQuery + item.query)}
    setState((prevState) => ({ ...prevState, product_names: tempQuery }));
    initializePagination();
  };

  const handleExport = () => {

    let since_date = "";
    let to_date = "";
    let car_plates = "";
    let card_ids = "";
    let tag_ids = "";

    //Assign proper format to filters.
    if (state.since_date !== "") {
      since_date = `since_date=${state.since_date.replace(/T/, " ")}&`;
    }
    if (state.to_date !== "") {
      to_date = `to_date=${state.to_date.replace(/T/, " ")}&`;
    }
    if (state.car_plates !== "") {
      car_plates = `car_plates=${state.car_plates}&`;
    }
    if (state.card_ids !== "") {
      card_ids = `card_ids=${state.card_ids}&`;
    }
    if (state.tag_ids !== "") {
      tag_ids = `tag_ids=${state.tag_ids}&`;
    }

  //Export data fetch
  fetch(
    `http://78.189.54.28:5000/sales_reports/all?` +
    state.station_serials +
    state.pump_names +
    state.nozzle_names +
    since_date +
    to_date +
    car_plates +
    state.product_names +
    state.tank_names +
    card_ids +
    tag_ids, 
    {
      headers: { 'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token },
    })
    .then((response) => response.json())
    .then((data) => {
      var wb = utils.book_new(),
      ws = utils.json_to_sheet(data.data.page_records);
  
      utils.book_append_sheet(wb, ws, "ExcelSheet");
  
      writeFile(wb, "ExcelExport.xlsx");
    });
  }

  const printReceipt = async (e) => {
    try {
        const response = await fetch(`http://78.189.54.28:5000/sales_reports/print`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token
            },
            body: JSON.stringify({receipt_id: Number(e.target.value)})
        });
        
        const data = await response.json();

        openReceiptPopup(data.data);
    } catch (error) {
        alert("Error fetching receipt\n", error);
    }
}

const openReceiptPopup = (data) => {
    const receiptWindow = window.open('', '_blank', 'width=300,height=600,menubar=no,toolbar=no,location=no,status=no');
    receiptWindow.document.write(generateReceiptHTML(data));
    receiptWindow.document.close();
}


  const generateReceiptHTML = (data) => {
    return `
        <html>
        <head>
        <style>
            body { font-family: Arial, sans-serif; width: 350px; margin: 0 auto; }
            .centeredClose { text-align: center; margin: 0; }
            .centered { text-align: center; }
            .close{ margin: 0; }
            .bold { font-weight: bold; }
            .dashed { border-bottom: 1px dashed black; margin: 5px 0; }
        </style>
    </head>    
            <body>
                <div style="margin-top: 20px;">
                    <h5 class="centered bold"><b>***START OF LEGAL RECEIPT***</b></h5>

                    <div class="centered">
                    <img src="${traIcon}" alt="Logo" class="centered" width="50">
                    </div>

                    <!-- Replace these with actual data values -->
                    <p class="centeredClose"><br></p>
                    <p class="centeredClose">LAKE OIL LIMITED</p>
                    <p class="centeredClose">P.O BOX 5055, DAR ES SALAAM - TANZANIA</p>
                    <p class="centeredClose">LAKE OIL ${data.sale.station_name}</p>
                    <p class="centeredClose">TEL: 0685729391</p>
                    <p class="centeredClose"><b>TIN:</b> ${data.vfd.trader.trader_tin}</p>
                    <p class="centeredClose"><b>VRN:</b> ${data.vfd.trader.trader_vrn}</p>
                    <p class="centeredClose"><b>SERIAL NUMBER:</b> ${data.vfd.trader.trader_serialno}</p>
                    <p class="centeredClose"><b>UIN:</b> ${data.vfd.trader.trader_uin}</p>

                    <p class="centered">TAX OFFICE: Large Taxpayer</p>

                    <p class="close">RECEIPT NUMBER:<span style="float:right">${data.vfd.receipt_no}</span></p>
                    <p class="close">ZNO:<span style="float:right">${data.vfd.receipt_znumber}</span></p>
                    <p class="close">RECEIPT DATE: ${data.vfd.receipt_date}<span style="float:right">TIME: ${data.vfd.receipt_time}</span></p>
                    <!-- ... continue for all 3 pairs -->

                    <div class="dashed"></div>

                    <p class="close">CUSTOMER NAME: ${data.vfd.customer.customer_name}</p>
                    <p class="close">CUSTOMER ID TYPE: ${data.vfd.customer.customer_idtype}</p>
                    <p class="close">CAR PLATE: ${data.sale.car_plate}</p>

                    <div class="dashed"></div>

                    <p class="close">PUMP: ${data.sale.pump_name} &nbsp;&nbsp;&nbsp;NOZZLE: ${data.sale.nozzle_name}</p>
                    <p class="close">${data.sale.product_name}: ${data.sale.price} x ${data.sale.volume} <span style="float:right">${data.sale.amount} ${Object.keys(data.vfd.totals.taxable).find(key => data.vfd.totals.taxable[key] !== 0)}</span></p>

                    <div class="dashed"></div>

                    <p class="bold">TOTAL EXCL. TAX:<span style="float:right">${data.vfd.totals.amount_exclusive}</span></p>
                    <div class="dashed"></div>
                    <p class="bold">TOTAL TAX:<span style="float:right">${data.vfd.totals.net_tax}</span></p>
                    <div class="dashed"></div>
                    <p class="bold">TOTAL INCL. TAX:<span style="float:right">${data.vfd.totals.amount_inclusive}</span></p>
                    <div class="dashed"></div>

                    <!-- repeat above two lines -->

                    <h4 class="centeredClose bold">RECEIPT VERIFICATION CODE</h4>
                    <h4 class="centeredClose bold">${data.vfd.receipt_rctvnumber}</h4>

                    <!-- Assuming you have a direct URL for the QR Code -->
                    <div class="centered">
                    <img src="${data.vfd.qrcode_url}" alt="QR Code" class="centered" width="100">
                    </div>

                    <h5 class="centered bold"><b>***END OF LEGAL RECEIPT***</b></h5>
                </div>
            </body>
        </html>
    `;
}


  //Initialize Nozzle ID when a new pump is selected.
  const initializeNozzleId = (e) => {
    setState((prevState) => ({ ...prevState, nozzle_names: "" }));
  };

  //Initialize pagination to page 1.
  const initializePagination = (e) => {
    setState((prevState) => ({ ...prevState, current_page: 1 }));
  };

  //Set current page to state
  const setCurrentPage = (event, newPage) => {
    setState((prevState) => ({ ...prevState, current_page: newPage }));
  };

  useEffect(() => {
    const abortController = new AbortController();

    let since_date = "";
    let to_date = "";
    let car_plates = "";
    let card_ids = "";
    let tag_ids = "";
    let current_page = "";

    //Assign proper format to filters.
    if (state.since_date !== "") {
      since_date = `since_date=${state.since_date.replace(/T/, " ")}&`;
    }
    if (state.to_date !== "") {
      to_date = `to_date=${state.to_date.replace(/T/, " ")}&`;
    }
    if (state.car_plates !== "") {
      car_plates = `car_plates=${state.car_plates}&`;
    }
    if (state.card_ids !== "") {
      card_ids = `card_ids=${state.card_ids}&`;
    }
    if (state.tag_ids !== "") {
      tag_ids = `tag_ids=${state.tag_ids}&`;
    }

    current_page = `page_number=${state.current_page}&`;
    
    //Filter and table fetch.
    // fetch(`http://78.189.54.28:5000/sales_reports/page`, {
    //   headers: { 'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token },
    //   signal: abortController.signal,
    // })
    //   .then((response) => response.json())
    //   .then((data) => setCurrentPageState(data.data));

    if (reportData.num_pages !== 0) {
      current_page = `page_number=${state.current_page}&`;

      fetch(
        `http://78.189.54.28:5000/sales_reports/page?` +
        current_page +
        state.station_serials +
        state.pump_names +
        state.nozzle_names +
        since_date +
        to_date +
        car_plates +
        state.product_names +
        state.tank_names +
        card_ids +
        tag_ids
        , {
          headers: { 'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token },
          signal: abortController.signal,
        })
        .then((response) => response.json())
        .then((data) => setReportData(data.data.page_records));

        //Export data fetch
        // fetch(
        //   `http://${sessionStorage.getItem("PumpIp")}/v1/sales_reports/report/page?` +
        //   state.pump_ids +
        //   state.nozzle_ids +
        //   since_date +
        //   to_date +
        //   car_plates +
        //   state.product_ids +
        //   state.tank_ids +
        //   card_ids +
        //   tag_ids + "per_page=20", 
        //   {
        //     headers: { 'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token },
        //     signal: abortController.signal,
        //   })
        //   .then((response) => response.json())
        //   .then((data) => setExportData(data.data.page_records));

      //Pagination fetch.
      fetch(
        `http://78.189.54.28:5000/sales_reports/page?` +
        state.station_serials +
        state.pump_names +
        state.nozzle_names +
        since_date +
        to_date +
        car_plates +
        state.product_names +
        state.tank_names +
        card_ids +
        tag_ids,
        {
          headers: { 'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token },
          signal: abortController.signal,
        })
        .then((response) => response.json())
        .then((data) => setCurrentPageState(data.data));
    }

// //Start fetch all filtered records to print without pagination.
// fetch(
//   `http://${sessionStorage.getItem("PumpIp")}/v1/sales_reports/page?` +
//   state.pump_ids +
//   state.nozzle_ids +
//   since_date +
//   to_date +
//   car_plates +
//   state.product_ids +
//   state.tank_ids +
//   card_ids +
//   tag_ids,
//   {
//     headers: { 'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token },
//   })
//   .then((response) => response.json())
//   .then((data) => {
//     if (data.data.num_pages <= 1) {
//       setArrayToPrint(data.data.page_records);
//     } else
//       for (let i = 1; i <= data.data.num_pages; i++) {
//         fetch(
//           `http://${sessionStorage.getItem("PumpIp")}/v1/sales_reports/page?page_number=` +
//           i +
//           "&" +
//           state.pump_ids +
//           state.nozzle_ids +
//           since_date +
//           to_date +
//           car_plates +
//           state.product_ids +
//           state.tank_ids +
//           card_ids +
//           tag_ids,
//           {
//             headers: { 'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token },
//           })
//           .then((response) => response.json())
//           .then((data) =>
//             setArrayToPrint((prevArray) => [
//               ...prevArray,
//               data.data.page_records,
//             ])
//           );
//       }
//   });
//   //End fetch table to print
      
      return () => {abortController.abort();}
      
  }, [
    state.station_serials,
    state.nozzle_names,
    state.pump_names,
    state.since_date,
    state.to_date,
    state.car_plates,
    state.product_names,
    state.tank_names,
    state.current_page,
    state.card_ids,
    state.tag_ids,
    reportData.num_pages,
  ])


useEffect(() => {
  //Fetch stations when filter bar is open
  if(toggleButton === "block"){
    fetch(`http://78.189.54.28:5000/station/all`, {
      headers: { Authorization: JSON.parse(sessionStorage.getItem('token')).data.access_token },
    })
      .then((response) => response.json())
      .then((data) => setStationsArray(data.data.map(item => ({ title: item.name, query: `station_serials=${item.serial_number}&` }))));
  }
}, [toggleButton])

useEffect(() => {
  //Fetch pumps when filter bar is open
  if(toggleButton === "block"){
    fetch(`http://78.189.54.28:5000/station/pumps`, {
      headers: { Authorization: JSON.parse(sessionStorage.getItem('token')).data.access_token },
    })
      .then((response) => response.json())
      .then((data) => setPumpsArray(data.data.page_records.map(item => ({ title: item.name, query: `pump_names=${item.name}&` }))));
  }
}, [toggleButton])

useEffect(() => {
 //Fetch nozzles when filter bar is open
 if(toggleButton === "block"){
   if (state.pump_names === "") {
       fetch(`http://78.189.54.28:5000/station/nozzles`, {
           headers: { 'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token },
       })
           .then(response => response.json())
           .then(data => setNozzleArrayData(data.data.page_records.map(item => ({ title: `Nozzle Name: ${item.name}, Nozzle Number: ${item.name}`, query: `nozzle_names=${item.name}&` }))))
   }
   else if (state.pump_names !== "") {
       fetch(`http://78.189.54.28:5000/station/nozzles?` + state.pump_names.slice(0, -1), {
           headers: { 'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token },
       })
           .then(response => response.json())
           .then(data => setNozzleArrayData(data.data.page_records.map(item => ({ title: `Nozzle Name: ${item.name}, Nozzle Number: ${item.name}`, query: `nozzle_names=${item.name}&` }))))
   }}
}, [toggleButton, state.pump_names])

useEffect(() => {
//Fetch tanks when filter bar is open
  if(toggleButton === "block"){
  fetch(`http://78.189.54.28:5000/station/tanks`, {
    headers: { Authorization: JSON.parse(sessionStorage.getItem('token')).data.access_token },
  })
    .then((response) => response.json())
    .then((data) => setFilterTankArrayData(data.data.page_records.map(item => ({ title: `Tank Name: ${item.name}`, query: `tank_names=${item.name}&` }))));}
}, [toggleButton]);

useEffect(() => {
  //Fetch products when filter bar is open
  if(toggleButton === "block"){
  fetch(`http://78.189.54.28:5000/station/products`, {
    headers: { Authorization: JSON.parse(sessionStorage.getItem('token')).data.access_token },
  })
    .then((response) => response.json())
    .then((data) => setFilterProductData(data.data.page_records.map(item => ({ title: `Product Name: ${item.name}`, query: `product_names=${item.name}&` }))));}
}, [toggleButton]);

  return (
    <>
      <p><b>*Click on <i>Export</i> button in <i>Filters</i> to download Excel sheet.</b></p>
      
      {/*Filter input fields start here*/}
      <div className="FilterBar" style={{ display: toggleButton }}>
      <h3>Select filters</h3>
        {/* <FormControl className={classes.formControl}>
          <InputLabel>Pump</InputLabel>
          <NativeSelect
            value={state.pump_ids}
            onChange={handleChange}
            onClick={initializeNozzleId}
            name="pump_ids"
          >
            <option disabled hidden></option>
            <FilterPumpArrayData token={token}  toggleButton={toggleButton}/>
          </NativeSelect>
          <FormHelperText className="FormHelperText">
            Select Pump
          </FormHelperText>
        </FormControl> */}

{/*Autocomplete and multiselect for stations starts here*/}
<div>
      { token.user_record.roles.id !== 1 && token.user_record.roles.id !== 3 ? (
        <></>
      ) : (
        <Autocomplete
          multiple
          id="checkboxes-tags-demo"
          options={stationsArray}
          onChange={handleStationsChange}
          disableCloseOnSelect
          getOptionLabel={(option) => option.title}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option.title}
            </li>
          )}
          style={{ width: 500 }}
          renderInput={(params) => (
            <TextField {...params} label="Station" placeholder="Select Station" />
          )}
        />
      )}
    </div>
{/*Autocomplete and multiselect for stations ends here*/}

<div className="FilterSection">
{/*Autocomplete and multiselect for pumps starts here*/}
      <Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={pumpsArray}
      onChange={handlePumpChange}
      onClick={initializeNozzleId}
      disableCloseOnSelect
      getOptionLabel={(option) => option.title}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField {...params} label="Pumps" placeholder="Select Pump" />
      )}
    />
{/*Autocomplete and multiselect for pumps ends here*/}

{/*Autocomplete and multiselect for nozzles starts here*/}
<Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={nozzleArrayData}
      onChange={handleNozzleChange}
      disableCloseOnSelect
      getOptionLabel={(option) => option.title}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField {...params} label="Nozzles" placeholder="Select Nozzle" />
      )}
    />
{/*Autocomplete and multiselect for nozzles ends here*/}
</div>

<div className="FilterSection">
{/*Autocomplete and multiselect for tanks starts here*/}
<Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={filterTankArrayData}
      onChange={handleTankChange}
      disableCloseOnSelect
      getOptionLabel={(option) => option.title}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField {...params} label="Tanks" placeholder="Select Tank" />
      )}
    />
{/*Autocomplete and multiselect for tanks ends here*/}
        {/* <FormControl className={classes.formControl}>
          <InputLabel>Nozzle</InputLabel>
          <NativeSelect
            value={state.nozzle_ids}
            onChange={handleChange}
            name="nozzle_ids"
          >
            <option disabled hidden></option>
            <FilterNozzleArrayData LogoutTimeout={LogoutTimeout} token={token} nozzlePumpId={state.pump_ids} toggleButton={toggleButton}/>
            <option value="">All Nozzles</option>
          </NativeSelect>
          <FormHelperText className="FormHelperText">
            Select Nozzle
          </FormHelperText>
        </FormControl> */}

        {/*Autocomplete and multiselect for products starts here*/}
<Autocomplete
      multiple
      id="checkboxes-tags-demo"
      options={filterProductData}
      onChange={handleProductChange}
      disableCloseOnSelect
      getOptionLabel={(option) => option.title}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField {...params} label="Products" placeholder="Select Product" />
      )}
    />
{/*Autocomplete and multiselect for products ends here*/}
</div>

        {/* <FormControl className={classes.formControl}>
          <InputLabel>Product Type</InputLabel>
          <NativeSelect
            value={state.product_ids}
            onChange={handleChange}
            onClick={initializeNozzleId}
            name="product_ids"
          >
            <option disabled hidden></option>
            <FilterProductData token={token} toggleButton={toggleButton}/>
          </NativeSelect>
          <FormHelperText className="FormHelperText">
            Select Product
          </FormHelperText>
        </FormControl> */}

        {/* <FormControl className={classes.formControl}>
          <InputLabel>Tank</InputLabel>
          <NativeSelect
            value={state.tank_ids}
            onChange={handleChange}
            onClick={initializeNozzleId}
            name="tank_ids"
          >
            <option disabled hidden></option>
            <FilterTankArrayData token={token} toggleButton={toggleButton}/>
          </NativeSelect>
          <FormHelperText className="FormHelperText">
            Select Tank
          </FormHelperText>
        </FormControl> */}

        <br />

        <FormControl className={classes.formControl} noValidate>
          <TextField
            name="car_plates"
            onChange={handleChange}
            value={state.car_plates}
            label="License Plate"
            id="outlined-margin-none-car-plates"
            className={classes.textField}
            variant="outlined"
          />
        </FormControl>

        <FormControl className={classes.formControl} noValidate>
          <TextField
            name="card_ids"
            onChange={handleChange}
            value={state.card_ids}
            label="Card ID"
            id="outlined-margin-none-card-ids"
            className={classes.textField}
            variant="outlined"
          />
        </FormControl>

        <FormControl className={classes.formControl} noValidate>
          <TextField
            name="tag_ids"
            onChange={handleChange}
            value={state.tag_ids}
            label="Tag ID"
            id="outlined-margin-none-tag-ids"
            className={classes.textField}
            variant="outlined"
          />
        </FormControl>
        <br />
        <FormControl className={classes.formControl} noValidate>
          <TextField
            name="since_date"
            onChange={handleChange}
            value={state.since_date}
            id="date"
            label="Since Date"
            type="datetime-local"
            className={classes.textField}
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              step: "1"
            }}
          />
          <FormHelperText className="FormHelperText">
            Select Start Date
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.formControl} noValidate>
          <TextField
            name="to_date"
            onChange={handleChange}
            value={state.to_date}
            id="date"
            label="To Date"
            type="datetime-local"
            className={classes.textField}
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{
              step: "1"
            }}
          />
          <FormHelperText className="FormHelperText">
            Select End Date
          </FormHelperText>
        </FormControl>

        <FormControl className={classes.FilterClearButtonClass} noValidate>
          <Button
            id="FilterClearButton"
            variant="contained"
            color="primary"
            onClick={handleExport}
          >
            Export
          </Button>
          </FormControl>
          
        <FormControl className={classes.FilterClearButtonClass} noValidate>
          <Button
            id="FilterClearButton"
            variant="contained"
            color="primary"
            onClick={() => {
              setState({
                current_page: 1,
                station_serials: "",
                pump_names: "",
                nozzle_names: "",
                since_date: "",
                to_date: "",
                car_plates: "",
                product_names: "",
                tank_names: "",
                card_ids: "",
                tag_ids: ""
              });
            }}
          >
            Clear Fields
          </Button>
        </FormControl>
        <hr />
      </div>

      {/*Filter input fields end here*/}

        {/*Check boxes for viewing and hiding columns start here*/}

        <div className="CheckboxesBar" style={{ display: checkboxesView }}>
          <h3>Select columns to view and hide</h3>
          <FormGroup>
          <FormControlLabel control={<Checkbox checked={checkedSalesId} onChange={handleCheckboxChangeSalesId} inputProps={{ 'aria-label': 'Sales ID' }}/>} label="Sales ID" />
          <FormControlLabel control={<Checkbox checked={checkedReceiptId} onChange={handleCheckboxChangeReceiptId} inputProps={{ 'aria-label': 'Receipt ID' }}/>} label="Receipt ID" />
          <FormControlLabel control={<Checkbox checked={checkedStation} onChange={handleCheckboxChangeStation} inputProps={{ 'aria-label': 'Station' }}/>} label="Station" />
          <FormControlLabel control={<Checkbox checked={checkedNozzle} onChange={handleCheckboxChangeNozzle} inputProps={{ 'aria-label': 'Nozzle' }}/>} label="Nozzle" />
          <FormControlLabel control={<Checkbox checked={checkedFuel} onChange={handleCheckboxChangeFuel} inputProps={{ 'aria-label': 'Fuel' }}/>} label="Fuel" />
          <FormControlLabel control={<Checkbox checked={checkedTank} onChange={handleCheckboxChangeTank} inputProps={{ 'aria-label': 'Tank' }}/>} label="Tank" />
          {/* <FormControlLabel control={<Checkbox checked={checkedVolume} onChange={handleCheckboxChangeVolume} inputProps={{ 'aria-label': 'Volume' }}/>} label="Volume" /> */}
          <FormControlLabel control={<Checkbox checked={checkedAmount} onChange={handleCheckboxChangeAmount} inputProps={{ 'aria-label': 'Amount' }}/>} label="Amount" />
          <FormControlLabel control={<Checkbox checked={checkedPrice} onChange={handleCheckboxChangePrice} inputProps={{ 'aria-label': 'Price' }}/>} label="Unit Price" />
          <FormControlLabel control={<Checkbox checked={checkedPlate} onChange={handleCheckboxChangePlate} inputProps={{ 'aria-label': 'Plate' }}/>} label="Car Plate" />
          </FormGroup>
          
        </div>

        {/*Check boxes for viewing and hiding columns end here*/}

      {/*Table render starts here*/}
      <table
        id="reportsTable"
        className="table table-striped table-bordered table-hover"
      >
        <thead className="thead-dark">
          <tr>
            <th scope="col">#</th>
            <th hidden={!checkedSalesId} scope="col">Sales ID</th>
            <th hidden={!checkedReceiptId} scope="col">Receipt ID</th>
            <th hidden={!checkedStation} scope="col">Station</th>
            <th scope="col">Creation Date</th>
            <th scope="col">Pump</th>
            <th hidden={!checkedNozzle} scope="col">Nozzle</th>
            <th hidden={!checkedFuel} scope="col">Fuel</th>
            <th hidden={!checkedTank} scope="col">Tank</th>
            <th scope="col">Volume</th>
            <th hidden={!checkedAmount} scope="col">Amount</th>
            <th hidden={!checkedPrice} scope="col">Unit Price</th>
            <th scope="col">Authorization</th>
            <th hidden={!checkedPlate} scope="col">Car Plate</th>
            <th scope="col">Receipt</th>
          </tr>
        </thead>
        <tbody>
          {reportData.map((item, index) => (
            <tr key={index}>
              <th scope="row">{(itemNo = itemNo + 1)}</th>
              <td hidden={!checkedSalesId}>{item.sales_id}</td>
              <td hidden={!checkedReceiptId}>{item.receipt_id}</td>
              <td hidden={!checkedStation}>{item.station_name}</td>
              <td>{item.creation_date}</td>
              <td>{item.pumps.name}</td>
              <td hidden={!checkedNozzle}>{item.nozzles.name}</td>
              <td hidden={!checkedFuel}>{item.products_info.name}</td>
              <td hidden={!checkedTank}>{item.tanks.name}</td>
              <td >{item.volume}</td>
              <td hidden={!checkedAmount}>{item.amount}</td>
              <td hidden={!checkedPrice}>{item.price}</td>
              <td>{item?.card_id ? `Card: ${item.card_id}` : item?.tag_id ? `Tag: ${item.tag_id}` : "Auto Authorized"}</td>
              <td hidden={!checkedPlate}>{item.car_plate}</td>
              <td><button value={item.receipt_id} className="fa fa-receipt" onClick={printReceipt}></button></td>
            </tr>
          ))}
          <tr>
            <th scope="col"></th>
            <th hidden={!checkedSalesId} scope="col"></th>
            <th hidden={!checkedReceiptId} scope="col"></th>
            <th hidden={!checkedStation} scope="col"></th>
            <th scope="col"></th>
            <th scope="col"></th>
            <th hidden={!checkedNozzle} scope="col"></th>
            <th hidden={!checkedFuel} scope="col"></th>
            <th hidden={!checkedTank} scope="col"></th>
            <th scope="col">Volume</th>
            <th hidden={!checkedAmount} scope="col">Amount</th>
            <th hidden={!checkedPrice} scope="col"></th>
            <th scope="col"></th>
            <th hidden={!checkedPlate} scope="col"></th>
            <th scope="col"></th>
          </tr>
          <tr>
            <th scope="row">Total</th>
            <td></td>
            <td hidden={!checkedSalesId}></td>
            <td hidden={!checkedReceiptId}></td>
            <td hidden={!checkedStation}></td>
            <td></td>
            <td hidden={!checkedNozzle}></td>
            <td hidden={!checkedFuel}></td>
            <td hidden={!checkedTank}></td>
            <td>{(reportData.reduce(function (acc, reportData) {return acc + reportData.volume}, 0)).toFixed(2)}</td>
            <td hidden={!checkedAmount}>{(reportData.reduce(function (acc, reportData) {return acc + reportData.amount}, 0)).toFixed(2)}</td>
            <td hidden={!checkedPrice}></td>
            <td></td>
            <td hidden={!checkedPlate}></td>
            <td></td>
          </tr>
        </tbody>
             </table>
      {/*Table render ends here*/}

      {/*Pagination bar*/}
      <div className="ReportPagination">
        <Pagination
          count={currentPageState.num_pages}
          page={state.current_page}
          onChange={(event, newPage) => {
            setCurrentPage(event, newPage);
          }}
          variant="outlined"
          color="primary"
        />
      </div>
    </>
  );
}
