import React, { useEffect, useRef } from "react";
import './NotAuthorized.css';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';


const NotAuthorized = ({ LogoutTimeout }) => {

    const hasRefreshedToken = useRef(false);

    useEffect(() => {

        if (!hasRefreshedToken.current) {
          LogoutTimeout();
          hasRefreshedToken.current = true;
        }
    }, [LogoutTimeout]);

  return(
<>
<div id="NotAuthorizedBox">
  <div>You are not authorized to view this page.</div>
  <IconButton aria-label="back" onClick={() => window.history.back()} >
          <ArrowBackIcon color = "primary" style={{fontSize: '3em'}}/>
        </IconButton>
  </div>
  </>
  );
  }

export default NotAuthorized;

export const NotAuthorizedSettings = ({ LogoutTimeout }) => {

  const hasRefreshedToken = useRef(false);

  useEffect(() => {

      if (!hasRefreshedToken.current) {
        LogoutTimeout();
        hasRefreshedToken.current = true;
      }
  }, [LogoutTimeout]);

return(
<>
<div id="NotAuthorizedBox">
<div>You are not authorized to view this page.</div>
</div>
</>
);
}

export const NotAuthorizedAlert = () => {

return(
<>
{ Swal.fire({
        title: 'Error!',
        text: 'You Are Not Authorized.',
        icon: 'error',
        confirmButtonText: 'Close',
      })}
</>
);
}