import React, { useState, useEffect } from "react";
import { PumpCard } from "../components/PumpComponents/PumpCard.js";
import Swal from "sweetalert2/dist/sweetalert2.js";
import './PumpArrayCreator.css';
//import socketIOClient from "socket.io-client";

export const PumpArrayCreator = ({ token, LogoutTimeout, pumpArrayCheckChange }) => {
  const [pumpArray, setPumpArray] = useState([]);
  const [checkChange, setCheckChange] = useState(0);
  //const [toPrint, setToPrint] = useState("");

  useEffect(() => {
    const abortController = new AbortController();
    
    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/pump/page`, {
      headers: { Authorization: JSON.parse(sessionStorage.getItem('token')).data.access_token },
      signal: abortController.signal,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Response not OK.", response);
        }
        return response.json();
      })
      .then((data) => setPumpArray(data.data.page_records))
      .catch((e) => {
        Swal.fire({
          title: "Error!",
          text: "Connection problem.",
          icon: "error",
          confirmButtonText: "Close",
        });
      });

      // //Rerender specifically to check pumps status.
      // const interval = setInterval(() => {
      //   setCheckChange(checkChange + 1);
      // }, 5000);
      // //end of interval.

      // return () => {abortController.abort(); clearInterval(interval);}
      return () => {abortController.abort();}

  }, [pumpArrayCheckChange, checkChange]);


//   useEffect(() => {
//       const socket = socketIOClient(`http://${sessionStorage.getItem("PumpIp")}/pump_chat`);
//       socket.on('print_notification', data => {
//         setToPrint(data);
//       })

//     return () => socket.disconnect();},
//     []);

//     useEffect(() => {
//       if(toPrint !== ""){
//         //console.log(toPrint);
//         //Remove comment below and edit Chrome to enable kiosk printing.
//         window.print();
//       }
// },
//     [toPrint]);

  return (
    <>
    <div className="PumpArrayClass">
      {pumpArray.map((item, index) => (
        <PumpCard
          key={index}
          approvalType={item.approval_types.name}
          approvalTypeId={item.approval_types.id}
          idCode={item.pump_identification_code}
          id={item.id}
          label={item.display_label}
          pumpName={item.name}
          connectionStatus={item.is_connected}
          numberOfNozzles={item.nozzles_number}
          pump_identification_code={item.pump_identification_code}
          token={token}
          LogoutTimeout={LogoutTimeout}
          checkChange = {checkChange}
          setCheckChange = {setCheckChange}
        />
      ))}
    </div>

    {/* <div className="PuCPrint">

  <div className='pumpCardSection'>

  <div className='PuCInfoPrint'>
    <h1 className='pumpButtonPrint'>Date: </h1>
    <p><b>{toPrint.creation_date}</b></p>
    </div>

    <div className='PuCInfoPrint'>
    <h1 className='pumpButtonPrint'>Pump: </h1>
    <h1><b>{toPrint.pump_id}</b></h1>
    </div>

    <div className='PuCInfoPrint'>
    <h1 className='pumpButtonPrint'>Nozzle: </h1>
    <h1><b>{toPrint.nozzle_id}</b></h1>
    </div>

    <div className='PuCInfoPrint'>
    <h1 className='pumpButtonPrint'>Card ID: </h1>
    <h1><b>{toPrint.card_id}</b></h1>
    </div>

    <div className='PuCInfoPrint'>
    <h1 className='pumpButtonPrint'>Total: </h1>
    <h1><b>{toPrint.amount}</b></h1>
    </div>

    <div className='PuCInfoPrint'>
    <h1 className='pumpButtonPrint'>Volume: </h1>
    <h1><b>{toPrint.volume}L</b></h1>
    </div>

    <div className='PuCInfoPrint'>
    <h1 className='pumpButtonPrint'>Unit Price: </h1>
    <h1><b>{toPrint.price}/L</b></h1>
    </div>

    <br/>

    <p>------------</p>

    <br/>
    <p>Visit us again.</p>
    <p><b>Thank you!</b></p>
    <br/>
    <br/>
  </div>
</div> */}

    </>
  );
};

export const PumpArrayData = (props) => {
  const [pumpArray, setPumpArray] = useState([]);

  useEffect(() => {
    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/pump/page`, {
      headers: { Authorization: JSON.parse(sessionStorage.getItem('token')).data.access_token },
    })
      .then((response) => response.json())
      .then((data) => setPumpArray(data.data.page_records));
  }, []);

  return pumpArray.map((item, index) => (
    <option key={index} value={item.id}>
      Pump Name: {item.display_label}, Pump ID: {item.id}.
    </option>
  ));
};

export const FilterPumpArrayData = ({toggleButton}) => {
  const [pumpArray, setPumpArray] = useState([]);

  useEffect(() => {
    if(toggleButton === "block"){
    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/pump/page`, {
      headers: { Authorization: JSON.parse(sessionStorage.getItem('token')).data.access_token },
    })
      .then((response) => response.json())
      .then((data) => setPumpArray(data.data.page_records));
  }
  }, [toggleButton]);

  // if(toggleButton === "block"){
  // return JSON.stringify(pumpArray.map(item => ({ title: item.display_label, year: `pump_ids=${item.id}&` })))}
  // else if(toggleButton === "none"){
  //   return null}
  return pumpArray.map((item, index) => (
    <option key={index} value={item.id}>
      Pump Name: {item.display_label}, Pump ID: {item.id}.
    </option>
  ));
};

export const PumpProtocolData = (props) => {
  const [pumpProtocols, setPumpProtocols] = useState([]);

  useEffect(() => {
    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/protocol/page`, {
      headers: { Authorization: JSON.parse(sessionStorage.getItem('token')).data.access_token },})
      .then((response) => response.json())
      .then((data) => {
        if(data.data.num_pages>1){
          for(let i=1; i<=data.data.num_pages; i++){
          fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/protocol/page?page_number=${i}`, {
          headers: { Authorization: JSON.parse(sessionStorage.getItem('token')).data.access_token },})
          .then((response) => response.json())
          .then((data) => setPumpProtocols(prevArray => [...prevArray, data.data.page_records]))}}
        else (setPumpProtocols(data.data.page_records))
        })
  }, []);

  return (
    <>
      {pumpProtocols.flat().map((item, index) => (
        <option key={index} value={item.id}>
          Protocol Name: {item.name}.
        </option>
      ))}
    </>
  );
};
export const PumpComPortData = (props) => {
  const [PumpComPortData, setPumpComPortData] = useState([]);

  useEffect(() => {
    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/com/page`, {
      headers: { Authorization: JSON.parse(sessionStorage.getItem('token')).data.access_token },
    })
      .then((response) => response.json())
      .then((data) => setPumpComPortData(data.data.page_records));
  }, []);

  return PumpComPortData.map((item, index) => (
    <option key={index} value={item.id}>
      COM Port No.: {item.name}.
    </option>
  ));
};

export const PumpApprovalTypeData = (props) => {
  const [PumpApprovalTypeData, setPumpApprovalTypeData] = useState([]);

  useEffect(() => {
    fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/approval_type/page`, {
      headers: { Authorization: JSON.parse(sessionStorage.getItem('token')).data.access_token },
    })
      .then((response) => response.json())
      .then((data) => setPumpApprovalTypeData(data.data.page_records));
  }, []);

  return PumpApprovalTypeData.map((item, index) => (
    <option key={index} value={item.id}>
      Approval Type: {item.name}.
    </option>
  ));
};
/*export const GasStationBranchData = () => {

    const [GasStationBranchData, setGasStationBranchData] = useState([]);

    useEffect(() => {
        fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/approval_type/page`)
            .then(response => response.json())
            .then(data => setGasStationBranchData(data.data.page_records))
    }, []);

    return (
        GasStationBranchData.map((item, index) =>

            <option key={index}>Gas Station Branch ID : {item.name}.</option>

        )
    )
}*/
