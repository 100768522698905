import React, { useState } from "react";
import "./Sidebar.css";
import { AddTankModal, DeleteTankModal, EditTankModal } from '../../TankComponents/TankModals';
import { AddPumpModal, DeletePumpModal, EditPumpModal } from '../../PumpComponents/PumpModals';
import {NotAuthorizedAlert} from '../NotAuthorized/NotAuthorized';
import { AddCustomers } from "../../Customers/AddCustomers";

const Sidebar = (props) => {

  const [modalShowAdd, setModalShowAdd] = useState(false);
  const [modalShowEdit, setModalShowEdit] = useState(false);
  const [modalShowDelete, setModalShowDelete] = useState(false);

  if (props.pageType === 'PumpCardSidebar') {
    return (
      <div className="sidenav" hidden={props.viewMenusState}>
        <button className="fas fa-plus-circle" onClick={() => {if( props.token.user_record.roles.id >= 4){NotAuthorizedAlert()} else{setModalShowAdd(true)}}} title="Add"></button>
        <AddPumpModal show={modalShowAdd} onHide={() => setModalShowAdd(false)} token={props.token} LogoutTimeout={props.LogoutTimeout} checkChange = {props.checkChange} setCheckChange = {props.setCheckChange}/>
        {/* <button className="fas fa-trash-alt" onClick={() => {if( props.token.user_record.roles.id >= 4){NotAuthorizedAlert()} else{setModalShowDelete(true)}}} title="Delete"></button> */}
        <DeletePumpModal show={modalShowDelete} onHide={() => setModalShowDelete(false)} token={props.token} LogoutTimeout={props.LogoutTimeout} checkChange = {props.checkChange} setCheckChange = {props.setCheckChange}/>
        <button className="fas fa-pencil-alt" onClick={() => {if( props.token.user_record.roles.id >= 4){NotAuthorizedAlert()} else{setModalShowEdit(true)}}} title="Edit"></button>
        <EditPumpModal show={modalShowEdit} onHide={() => setModalShowEdit(false)} token={props.token} LogoutTimeout={props.LogoutTimeout} checkChange = {props.checkChange} setCheckChange = {props.setCheckChange}/>
        <button className="fas fa-sync-alt" onClick={() => window.location.reload(false)} title="Refresh"></button>
      </div>
    );
  }
  else if (props.pageType === 'TankSidebar') {
    return (
      <div className="sidenav">
        <button className="fas fa-plus-circle" onClick={() => {if( props.token.user_record.roles.id >= 4){NotAuthorizedAlert()} else{setModalShowAdd(true)}}} title="Add"></button>
        <AddTankModal show={modalShowAdd} onHide={() => setModalShowAdd(false)} token={props.token} LogoutTimeout={props.LogoutTimeout} checkChange = {props.checkChange} setCheckChange = {props.setCheckChange}/>
        {/* <button className="fas fa-trash-alt" onClick={() => {if( props.token.user_record.roles.id >= 4){NotAuthorizedAlert()} else{setModalShowDelete(true)}}} title="Delete"></button> */}
        <DeleteTankModal show={modalShowDelete} onHide={() => setModalShowDelete(false)} token={props.token} LogoutTimeout={props.LogoutTimeout} checkChange = {props.checkChange} setCheckChange = {props.setCheckChange}/>
        <button className="fas fa-pencil-alt" onClick={() => {if( props.token.user_record.roles.id >= 4){NotAuthorizedAlert()} else{setModalShowEdit(true)}}} title="Edit"></button>
        <EditTankModal show={modalShowEdit} onHide={() => setModalShowEdit(false)} token={props.token} LogoutTimeout={props.LogoutTimeout} checkChange = {props.checkChange} setCheckChange = {props.setCheckChange}/>
        <button className="fas fa-sync-alt" onClick={() => window.location.reload(false)} title="Refresh"></button>
      </div>
    );
  }
  else if (props.pageType === 'ShiftsSidebar') {
    return (
      <div className="sidenav">
        <button className="fas fa-plus-circle" title="Add"></button>
        <button className="fas fa-trash-alt" title="Delete"></button>
        <button className="fas fa-pencil-alt" title="Edit"></button>
        <button className="fas fa-sync-alt" onClick={() => window.location.reload(false)} title="Refresh"></button>
      </div>
    );
  }
  else if (props.pageType === 'ReportsSidebar') {
    return (
      <div className="sidenav">
        <button className="fas fa-filter" onClick={props.showFields} title="Filter Parameters"></button>
        <button className="sidenav fas fa-print" onClick={() => window.print()} title="Print"></button>
        {/* <button className="fas fa-file-export" onClick={() => window.print()} title="Export as Excel sheet"></button> */}
        {/*<FilterReportsModal show={modalShowFilter} onHide={() => {setModalShowFilter(false)}} />*/}
        <button className="fas fa-eye" id="hideColumnButton" onClick={props.showCheckboxes} title="Hide/View columns"></button>
        <button className="fas fa-sync-alt" onClick={() => window.location.reload(false)} title="Refresh"></button>
      </div>
    );
  }
    else if (props.pageType === 'PeriodicReportsSidebar') {
    return (
      <div className="sidenav">
        <button className="sidenav fas fa-print" onClick={() => window.print()} title="Print"></button>
        <button className="fas fa-sync-alt" onClick={() => window.location.reload(false)} title="Refresh"></button>
      </div>
    );
  }
  else if (props.pageType === 'CustomersSidebar') {
    return (
      <div className="sidenav">
        <button className="fas fa-plus-circle" onClick={() => {if( props.token.user_record.roles.id === 5){NotAuthorizedAlert()} else{setModalShowAdd(true)}}} title="Add"></button>
        <AddCustomers show={modalShowAdd} checkChange={props.checkChange} setCheckChange={props.setCheckChange} onHide={() => setModalShowAdd(false)} token={props.token} LogoutTimeout={props.LogoutTimeout}/>
        <button className="fas fa-sync-alt" onClick={() => window.location.reload(false)} title="Refresh"></button>
      </div>
    );
  }
  else if (props.pageType === 'VehiclesSidebar') {
    return (
      <div className="sidenav">
        <button className="fas fa-arrow-circle-left" onClick={() => window.history.back()} title="Go Back"></button>
        <button className="fas fa-sync-alt" onClick={() => window.location.reload(false)} title="Refresh"></button>
      </div>
    );
  }
  else if (props.pageType === 'AddVehicleSidebar') {
    return (
      <div className="sidenav">
        <button className="fas fa-arrow-circle-left" onClick={() => window.history.back()} title="Go Back"></button>
        <button className="fas fa-sync-alt" onClick={() => window.location.reload(false)} title="Refresh"></button>
      </div>
    );
  }
};

export default Sidebar;