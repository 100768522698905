import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
//import NativeSelect from '@material-ui/core/NativeSelect';
//import TextField from '@material-ui/core/TextField';
//import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import Button from 'react-bootstrap/Button'
import './TagReaders.css';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

//to select tag reader in nozzle modal
export const TagReaderData = ({ token }) => {
  const [tagReaderData, setTagReaderData] = useState([]);

  useEffect(() => {
    fetch(`http://${sessionStorage.getItem("ReaderIp")}/v1/tag_reader/page`, {
      headers: { Authorization: token.access_token },
    })
      .then((response) => response.json())
      .then((data) => setTagReaderData(data.data.page_records));
  }, [token.access_token]);

  return tagReaderData.map((item, index) => (
    <option key={index} value={item.id}>
       Tag Reader ID: {item.id}.
    </option>
  ));
};

export default function TagReaders({ token, LogoutTimeout }) {

  const [TagReadersState, setTagReadersState] = useState({
    id: '',
    com_id: '',
    identification_code: '',
  });

  const [TagReadersData, setTagReadersData] = useState([]);
  const [TagReadersIdState, setTagReadersIdState] = useState({ id: "" });
  const [pumpComPortData, setPumpComPortData] = useState([]);
  const classes = useStyles();
  const hasRefreshedToken = useRef(false);
  const [errors, setErrors] = useState({});
  const [formError, setFormError] = useState("");

  const handleChange = (e) => {

    let value;
    const name = e.target.name;
    if ((!isNaN(e.target.value) && e.target.value.length > 0) && e.target.name !== "id") { value = Number(e.target.value) }
    else { value = e.target.value; }

    setTagReadersState((prevState) => ({ ...prevState, [name]: value }));

    if(!!errors[name]){setErrors({...errors, [name]: ''})};
  };

  const handleTagReadersChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setTagReadersIdState((prevState) => ({ ...prevState, [name]: value }));

    fetch(`http://${sessionStorage.getItem("ReaderIp")}/v1/tag_reader/${e.target.value}`, {
      headers: { Authorization: token.access_token },
    })
      .then((response) => response.json())
      .then((data) =>
        setTagReadersState({
          id: data.data.id,
          com_id: data.data.coms.id,
          identification_code: data.data.identification_code,
        })
      );

      setErrors({});
      setFormError("");
  };

  //Get all Tag Readers.
  useEffect(() => {

    if (!hasRefreshedToken.current) {
      LogoutTimeout();
      hasRefreshedToken.current = true;
    }


    fetch(`http://${sessionStorage.getItem("ReaderIp")}/v1/tag_reader/page`, {
      headers: { Authorization: token.access_token },
    })
      .then((response) => response.json())
      .then((data) => setTagReadersData(data.data.page_records));

    fetch(`http://${sessionStorage.getItem("ReaderIp")}/v1/com/page`, {
      headers: { Authorization: token.access_token },
    })
      .then((response) => response.json())
      .then((data) => setPumpComPortData(data.data.page_records));


  }, [TagReadersState.id, TagReadersState.com_id, token.access_token, LogoutTimeout]);

  function submitAdd() {
    //e.preventDefault()

    let addValues = Object.fromEntries(Object.entries(TagReadersState).filter(([_, v]) => v !== ''));

    fetch(`http://${sessionStorage.getItem("ReaderIp")}/v1/tag_reader/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token.access_token,
      },
      body: JSON.stringify({com_id: addValues.com_id, identification_code: addValues.identification_code}),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Response not OK.", response);
        }
        return response.json();
      })
      .then((data) => {
        if (data.status_code === 403) {
          Swal.fire({
            title: "Error!",
            text: data.message,
            icon: "error",
            confirmButtonText: "Close",
            confirmButtonColor: "#F27474",
          });
        }
        else if (data.status_code === 630 || data.status_code === 400) {
          setFormError("This reader already exists")
        }
          else if (data.status_code < 200 || data.status_code > 299) {
            setErrors(data.data.body.wrong_arguments);
            setFormError("Check your input!")
          }
          else if (data.status_code >= 200 && data.status_code <= 299) {
          Swal.fire({
            title: "Success!",
            text: "Tag Reader Added Successfully",
            icon: "success",
            confirmButtonText: "Close",
            confirmButtonColor: "#A5DC86",
          });
          clearFields();
        }
      })
      .catch((e) => {
        Swal.fire({
          title: "Error!",
          text: "Connection problem.",
          icon: "error",
          confirmButtonText: "Close",
        });
      });
  }

  function submitDelete() {
    //e.preventDefault()
    if (!TagReadersIdState.id) {
      setFormError("Please select a tag reader.")
    }
    else (fetch(`http://${sessionStorage.getItem("ReaderIp")}/v1/tag_reader/${TagReadersIdState.id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: token.access_token,
      },
      body: JSON.stringify(null),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Response not OK.", response);
        }
        return response.json();
      })
      .then((data) => {
        if (data.status_code < 200 || data.status_code > 299) {
          Swal.fire({
            title: "Error!",
            text: "Check your input!",
            icon: "error",
            confirmButtonText: "Try Again",
            confirmButtonColor: "#F27474",
          });
        } else if (data.status_code >= 200 && data.status_code <= 299) {
          Swal.fire({
            title: "Success!",
            text: "Tag Reader Deleted Successfully",
            icon: "success",
            confirmButtonText: "Close",
            confirmButtonColor: "#A5DC86",
          });
          clearFields();
        }
      })
      .catch((e) => {
        Swal.fire({
          title: "Error!",
          text: "Connection problem.",
          icon: "error",
          confirmButtonText: "Close",
        });
      })
  )}

  function submitEdit() {
    //e.preventDefault()
    if (!TagReadersIdState.id) {
      setFormError("Please select a tag reader.")
    }
    else (fetch(`http://${sessionStorage.getItem("ReaderIp")}/v1/tag_reader/${TagReadersIdState.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: token.access_token,
      },
      body: JSON.stringify({com_id:TagReadersState.com_id, identification_code:TagReadersState.identification_code}),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Response not OK.", response);
        }
        return response.json();
      })
      .then((data) => {
        if (data.status_code < 200 || data.status_code > 299) {
          Swal.fire({
            title: "Error!",
            text: "Check your input!",
            icon: "error",
            confirmButtonText: "Try Again",
            confirmButtonColor: "#F27474",
          });
        } else if (data.status_code >= 200 && data.status_code <= 299) {
          Swal.fire({
            title: "Success!",
            text: "Tag Reader Edited Successfully",
            icon: "success",
            confirmButtonText: "Close",
            confirmButtonColor: "#A5DC86",
          });
          clearFields();
        }
      })
      .catch((e) => {
        Swal.fire({
          title: "Error!",
          text: "Connection problem.",
          icon: "error",
          confirmButtonText: "Close",
        });
      })
  )}

  const clearFields = () => {
    setTagReadersIdState({ id: "" });
    setTagReadersState({ identification_code: "", com_id: "" });
    setErrors({});
    setFormError("");
  };

  return (
    <React.Fragment>
      <Container maxWidth="sm">

        {/*Input fields to add card*/}
        <FormControl id="TagReadersForm" className={classes.formControl}>
          <h3>Tag Reader Settings</h3>
          <Grid container>
            <Grid item xs={12} className="TagReadersGrid">
              <FormControl>
              <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                name="id"
                value={TagReadersIdState.id}
                onChange={handleTagReadersChange}
                displayEmpty
                className={classes.selectEmpty}
              >
                <MenuItem value="" disabled>
                  <em>Tag Readers</em>
                </MenuItem>
                {
                  TagReadersData.map((item, index) =>

                    <MenuItem key={index} value={item.id}> Tag Reader ID: {item.id}</MenuItem>
                  )
                }
              </Select>
              <FormHelperText>Available Tag Readers</FormHelperText>
              </FormControl>

              <FormControl>
              <TextField
                id="standard-helperText-identification-code"
                name="identification_code"
                label="Identification Code (Cannot be edited.)"
                value={TagReadersState.identification_code}
                onChange={handleChange}
                error={!!errors.identification_code}
              />

              <FormHelperText error={!!errors.identification_code}>{errors.identification_code ? errors.identification_code : "Enter Reader Identification Code"}</FormHelperText>

              </FormControl>

              {/* <TextField
                id="standard-helperText-id"
                name="id"
                label="Id (Cannot be edited.)"
                value={TagReadersState.id}
                onChange={handleChange}
              />
              <FormHelperText>Enter Tag Reader ID</FormHelperText> */}

              {/* <FormControl>
              <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                name="nozzle_id"
                value={TagReadersState.nozzle_id}
                onChange={handleChange}
                displayEmpty
                className={classes.selectEmpty}
              >
                <MenuItem value="" disabled>
                  <em>Nozzle</em>
                </MenuItem>
                {
                  NozzleArrayData.map((item, index) =>

                    <MenuItem key={index} value={item.id}>Nozzle: {item.name}</MenuItem>
                  )
                }
              </Select>
              <FormHelperText>Available Nozzles</FormHelperText>
              </FormControl> */}
              <FormControl>
              <Select
                labelId="demo-simple-select-placeholder-label-label"
                id="demo-simple-select-placeholder-label"
                name="com_id"
                value={TagReadersState.com_id}
                onChange={handleChange}
                displayEmpty
                className={classes.selectEmpty}
                error={!!errors.com_id}
              >
                <MenuItem value="" disabled>
                  <em>COM Port</em>
                </MenuItem>
                {
                  pumpComPortData.map((item, index) =>

                    <MenuItem key={index} value={item.id}>COM Port: {item.name}</MenuItem>
                  )
                }
              </Select>
              <FormHelperText error={!!errors.com_id}>{errors.com_id ? errors.com_id : "Available COM Ports"}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <FormHelperText error={!!Object.keys(errors).length || formError !== ""}><b>{formError}</b></FormHelperText>

          <div className="TagReadersButtons">
            <Button variant="primary" onClick={submitAdd}>Add</Button>
            <Button variant="danger" onClick={submitDelete}>Delete</Button>
            <Button variant="warning" onClick={submitEdit}>Edit</Button>
            <Button variant="secondary" onClick={() => { clearFields() }}>Clear Fields</Button>
          </div>

        </FormControl>

      </Container>
    </React.Fragment>
  );
}