import React from 'react';
// import shiftsIcon from '../../../media/icons/058-seismic.png';
import reportsIcon from '../../../media/icons/029-logistics.gif';
import './NavigationBar.css';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

export const NavigationBar = (props) => {

  const location = useLocation();

  /*const LogoutTimeout = () => {

    const [logoutState, setLogoutState] = useState();
    fetch(`http://${sessionStorage.getItem("ReaderIp")}/v1/authentication/refresh`, 
    {
      headers: { 'Authorization': props.token.access_token },
    })
    .then(response => response.json())
    .then(data => setLogoutState(data.data.refresh_token))
    
  if(!state) {
    alert("Session Timed Out");
    sessionStorage.clear();
    window.location.reload(false);
  }}
  
  setInterval(LogoutTimeout, 5000);*/

return (
  <div className="NavBarIcons" hidden={props.viewMenusState}>

    <Link to="/Reports" onClick={() => {if (location.pathname !== '/Reports' && props.showFilterFields === "block"){props.setShowFilterState();} if (location.pathname !== '/Reports' && props.showReportsCheckboxes === "block"){props.setReportsCheckboxesState();}props.LogoutTimeout();}} className="NavIcons" id={location.pathname === '/Reports' ? 'WithBackground' : 'WithoutBackground'} style={{ textDecorationColor: '#ff624b' }}>
      <img src={reportsIcon} alt="reports" width="50em" height="50em" />
      <p className="NavIconsText">Reports</p>
    </Link>

    <Link to="/PeriodicReports" onClick={() => {if (location.pathname !== '/PeriodicReports' && props.showFilterFields === "block"){props.setShowFilterState();} if (location.pathname !== '/PeriodicReports' && props.showReportsCheckboxes === "block"){props.setReportsCheckboxesState();}props.LogoutTimeout();}} className="NavIcons" id={location.pathname === '/PeriodicReports' ? 'WithBackground' : 'WithoutBackground'} style={{ textDecorationColor: '#ff624b' }}>
      <img src={reportsIcon} alt="reports" width="50em" height="50em" />
      <p className="NavIconsText">Periodic Reports</p>
    </Link>

  </div>
);}