import React, { useState, useEffect } from 'react';
import { ProbeCard } from '../components/ProbeComponents/ProbeCard';
import { Modal } from 'react-bootstrap';
import './ProbeArrayCreator.css';
import Button from 'react-bootstrap/Button';
import Background from "./Background.jpg";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import {AddProbeModal, EditProbeModal} from '../components/ProbeComponents/ProbeModals';
import {NotAuthorizedAlert} from '../components/NavigationComponents/NotAuthorized/NotAuthorized';

export const ProbeArrayCreator = ({ProbeID}) => {


    const [ProbeArray, setProbeArray] = useState({});

    useEffect(() => {
        const abortController = new AbortController();

        fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/probe/` + ProbeID,
            {
                headers: { 'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token },
                signal: abortController.signal,
            })
            .then(response => response.json())
            .then(data => setProbeArray(data.data))

            return () => {abortController.abort();}
    }, [ProbeID]);

    if(ProbeID){ return (
            <ProbeCard
                product_HH={ProbeArray.product_HH}
                id={ProbeArray.id}
                probe_address={ProbeArray.probe_address}
                water_H={ProbeArray.water_H}
                name={ProbeArray.name}
                tanks={ProbeArray.tanks}
                water_L={ProbeArray.water_L}
                protocols={ProbeArray.protocols}
                is_configured={ProbeArray.is_configured}
                product_H={ProbeArray.product_H}
                baud={ProbeArray.baud}
                temperature_H={ProbeArray.temperature_H}
                product_LL={ProbeArray.product_LL}
                channel={ProbeArray.channel}
                product_L={ProbeArray.product_L}
                probe_identification_code={ProbeArray.probe_identification_code}
                temperature_L={ProbeArray.temperature_L}
                />
    )}
    else return (<></>)
}

export const ProbeArrayModal = ({LogoutTimeout, show, onHide, label, token, ProbeID, tankName}) => {

    const [modalShowAdd, setModalShowAdd] = useState(false);
    const [modalShowEdit, setModalShowEdit] = useState(false);

    const finalizeProbe = () => {
        fetch(`http://${sessionStorage.getItem("PumpIp")}/v1/probe/upload_probes_configurations`, 
        {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' ,'Authorization': token.access_token},
        body: JSON.stringify(null)
        })
        .then((response) => {
        if (!response.ok) {
          throw new Error("Response not OK.", response);
        }
        return response.json();
        })
        .then((data) => {
        Swal.fire({
          text: data.message+'.',
          icon: 'info',
          confirmButtonText: 'Close',
          confirmButtonColor: '#F27474'
        })
        })
        .catch((e) => {
        Swal.fire({
          title: 'Error!',
          text: 'Could not connect to server.',
          icon: 'error',
          confirmButtonText: 'Try Again',
          confirmButtonColor: '#F27474'
        })
        });
    };


    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    Tank ({tankName}) Probe
                </Modal.Title>
            </Modal.Header>
            <div style={{ backgroundImage: `url(${Background})`, backgroundSize: "cover" }}>
                <div className="Buttons">
                    <button className="fas fa-plus-circle" onClick={() => {if( token.user_record.roles.id >= 4){NotAuthorizedAlert()} else{setModalShowAdd(true)}}} title="Add"></button>
                    <AddProbeModal show={modalShowAdd} onHide={() => setModalShowAdd(false)} ProbeID={ProbeID} token = {token} />
                    <button className="fas fa-pencil-alt" onClick={() => {if( token.user_record.roles.id >= 4){NotAuthorizedAlert()} else{setModalShowEdit(true)}}} title="Edit"></button>
                    <EditProbeModal show={modalShowEdit} onHide={() => setModalShowEdit(false)} ProbeID={ProbeID} token = {token} />
                    <button className="fas fa-sync-alt" onClick={() => window.location.reload(false)} title="Refresh"></button>
                </div>
                <Modal.Body >

                    <ProbeArrayCreator LogoutTimeout={LogoutTimeout}  token = {token} ProbeID={ProbeID}/>

                    <hr/>
                    <br/><p><b>Required: </b>Press finalize <i>after</i> adding or editing probes.</p> <Button variant="primary" onClick={finalizeProbe}>Finalize</Button> <br/>

                </Modal.Body>
            </div>
            <Modal.Footer>
                <Button onClick={onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}