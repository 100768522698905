import React, { useState } from 'react';
import './App.css';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { NavigationBar } from './components/NavigationComponents/NavigationBar/NavigationBar';
import {Reports} from './Reports/Reports';
import CustomizedTables from './Reports/PeriodicReports';
import Sidebar from './components/NavigationComponents/Sidebar/Sidebar';
import {PumpArrayCreator} from './ArrayCreators/PumpArrayCreator';
import {TankArrayCreator} from './ArrayCreators/TankArrayCreator';
import Footer from './components/NavigationComponents/Footer/Footer';
import AlarmsArrayCreator from './ArrayCreators/AlarmArrayCreator';
import '../node_modules/@fortawesome/fontawesome-free/css/all.css';
import Login from './components/Login/Login';
import useToken from './components/App/useToken';
import NotAuthorized from './components/NavigationComponents/NotAuthorized/NotAuthorized';

function App() {

  const [showFilterFields, setShowFilterFields] = useState("none");
  const [showReportsCheckboxes, setShowReportsCheckboxes] = useState("none");
  const [checkChange, setCheckChange] = useState(0);
  const { token, setToken } = useToken();
  const [viewMenusState, setViewMenusState] = useState(false);


/*sessionStorage.setItem('saved', new Date().getTime());

const LogoutTimeout = () => {
let hours = 0.5
if(token) {
let saved = sessionStorage.getItem('saved')
if (saved && (new Date().getTime() - saved > hours * 60 * 60 * 1000)) {
  alert("Session Expired");
  sessionStorage.clear();
  window.location.reload(false);
}
}}
window.location.hostname}:4000
setInterval(LogoutTimeout, 1000);*/

  //Timeout check begin.
const LogoutTimeout = () => {
    fetch(`http://78.189.54.28:5000/auth/refresh`, {
      headers: { Authorization: token.refresh_token },
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error("Response not OK.", response);
      }
      return response.json();
    })
      .then((data) => {
        if (data.data === null) {
          alert("Session Timed Out");
          sessionStorage.clear();
          window.location.reload(false);
        } else {
          setToken(data);
        }
      })
      .catch((e) => {
        alert("Could not connect to server...");
        sessionStorage.clear();
        window.location.reload(false);
      });
};
  //Timeout check end.

  const setShowFilterState = () => {
    if (showFilterFields === "none")
    setShowFilterFields("block")
    if (showFilterFields === "block")
      setShowFilterFields("none")
  }

  const setReportsCheckboxesState = () => {
    if (showReportsCheckboxes === "none")
    setShowReportsCheckboxes("block")
    if (showReportsCheckboxes === "block")
    setShowReportsCheckboxes("none")
  }

  if(!token) {
    return(
    <> 
    <Login setToken={setToken} />
    <Router>
    <Footer token={token} setViewMenusState={setViewMenusState} viewMenusState={viewMenusState}/>
    </Router>
    </>)
  }

  else if( token.user_record.roles.id <= 4 ){
  
  return (
    <React.Fragment>
  <Router>
  <NavigationBar viewMenusState={viewMenusState} LogoutTimeout={LogoutTimeout} showFilterFields={showFilterFields} setShowFilterState={setShowFilterState} showReportsCheckboxes={showReportsCheckboxes} setReportsCheckboxesState={setReportsCheckboxesState}/>
  {/* <button onClick={() => {print("Hello")}}></button> */}
    <Switch>

  <Route exact path={["/", "/Reports"]}>
  <Sidebar pageType="ReportsSidebar" showFields={setShowFilterState} showCheckboxes={setReportsCheckboxesState} token = {token} LogoutTimeout={LogoutTimeout}/>
  <Reports toggleButton={showFilterFields} checkboxesView={showReportsCheckboxes} token = {token} LogoutTimeout={LogoutTimeout} />
  </Route>

  <Route path="/PeriodicReports">
  <Sidebar pageType="PeriodicReportsSidebar" showFields={setShowFilterState} showCheckboxes={setReportsCheckboxesState} token = {token} LogoutTimeout={LogoutTimeout}/>
  <CustomizedTables toggleButton={showFilterFields} checkboxesView={showReportsCheckboxes} token = {token} LogoutTimeout={LogoutTimeout} />
  </Route>

</Switch>
<Footer token={token} setViewMenusState={setViewMenusState} viewMenusState={viewMenusState}/>
  </Router>
</React.Fragment>

  );}

  else if( token.user_record.roles.id === 5){
  
    return (
      <React.Fragment>
    <Router>
    <NavigationBar viewMenusState={viewMenusState} LogoutTimeout={LogoutTimeout} showFilterFields={showFilterFields} setShowFilterState={setShowFilterState} showReportsCheckboxes={showReportsCheckboxes} setReportsCheckboxesState={setReportsCheckboxesState}/>
      <Switch>
  
    <Route exact path={["/", "/PumpCard"]}>
    <Sidebar pageType="PumpCardSidebar" viewMenusState={viewMenusState} token = {token} LogoutTimeout={LogoutTimeout} checkChange = {checkChange} setCheckChange = {setCheckChange}/>
      {/* <TotalizerContext.Provider value={totalizerData}> */}
        <PumpArrayCreator token = {token} LogoutTimeout={LogoutTimeout}/>  
    {/* </TotalizerContext.Provider> */}
    </Route>
  
    <Route path="/TankGauge">
    <Sidebar pageType="TankSidebar" token = {token} LogoutTimeout={LogoutTimeout}/>
    <TankArrayCreator token = {token} LogoutTimeout={LogoutTimeout}/>
    </Route>
  
    <Route path="/Shifts">
    <Sidebar pageType="ShiftsSidebar" token = {token} LogoutTimeout={LogoutTimeout}/>
    </Route>

    <Route path="/Reports">
    <Sidebar pageType="ReportsSidebar" showFields={setShowFilterState} showCheckboxes={setReportsCheckboxesState} token = {token} LogoutTimeout={LogoutTimeout}/>
    <Reports toggleButton={showFilterFields} checkboxesView={showReportsCheckboxes} token = {token} LogoutTimeout={LogoutTimeout}/>
    </Route>

    <Route path="/Customers">
    <NotAuthorized token = {token} LogoutTimeout={LogoutTimeout}/>
    </Route>
  
    <Route path="/Alarms">
    <AlarmsArrayCreator token = {token} LogoutTimeout={LogoutTimeout}/>
    </Route>

    <Route path="/Staff">
    <NotAuthorized token = {token} LogoutTimeout={LogoutTimeout}/>
    </Route>
  
    <Route path="/Settings">
    <NotAuthorized token = {token} LogoutTimeout={LogoutTimeout}/>
    </Route>
  
  </Switch>
  <Footer token={token} setViewMenusState={setViewMenusState} viewMenusState={viewMenusState}/>
    </Router>
  </React.Fragment>
  
    );}

  else {return (<NotAuthorized LogoutTimeout={LogoutTimeout}/>)}}

export default App;