import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import './PeriodicReports.css';
import ReactDOMServer from 'react-dom/server';
import Button from 'react-bootstrap/Button'
import traIcon from '../media/icons/logo.png';
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#bdc3c7',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const ReceiptContent = ({data, dataArray, station}) => {
  return (
      <div style={{ margin: '100px 40px 100px 40px', border: '1px solid black' }}>
          <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>****START OF PERIODIC REPORT****</h3>
          <img src={traIcon} alt="logo" style={{ display: 'block', margin: '0 auto'}} width="50em" height="50em"/>
          <h3 style={{ textAlign: 'center', fontWeight: 'bold' }}>LAKE OIL LIMITED</h3>
          <h4 style={{ textAlign: 'center', margin: '0' , fontWeight: 'bold' }}>P.O BOX 5055, DAR ES SALAAM - TANZANIA</h4>
          <h4 style={{ textAlign: 'center', margin: '0' , fontWeight: 'bold' }}>LAKE OIL {station}</h4>
          <p style={{ textAlign: 'center', margin: '0' }}><b>MOBILE: </b>0685729391</p>
          <p style={{ textAlign: 'center', margin: '0' }}><b>TIN: </b>104911757</p>
          <p style={{ textAlign: 'center', margin: '0' }}><b>VRN: </b>10019095T</p>
          <p style={{ textAlign: 'center', margin: '0' }}><b>SERIAL NUMBER: </b>10TZ101907</p>
          <p style={{ textAlign: 'center', margin: '0' }}><b>UIN: </b>342SVZ5433455-234398209384098302948029TZ23423</p>
          <p style={{ textAlign: 'center', margin: '0' }}><b>TAX OFFICE: Large Taxpayer</b></p>


          <hr style={{ border: 'dashed 1px' }} />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <span><b>START: </b>{data.dates.start}</span>
              <span><b>END: </b>{data.dates.end}</span>
          </div>
          <hr style={{ border: 'dashed 1px' }} />

          <p style={{ textAlign: 'left' }}><b>TOTAL LITRE: </b>{parseFloat(data.totals.total_volume).toLocaleString('en-US')} Ltr</p>
          <p style={{ textAlign: 'left' }}><b>TOTAL AMOUNT: </b>{parseFloat(data.totals.total_amount).toLocaleString('en-US')}</p>
          <p style={{ textAlign: 'left' }}><b>DISCOUNT AMOUNT: </b>0</p>
          <p style={{ textAlign: 'left' }}><b>NET AMOUNT: </b>{parseFloat(data.totals.net_amount).toLocaleString('en-US')}</p>

          <hr style={{ border: 'dashed 1px' }} />
          <h3 style={{ textAlign: 'center' }}>Product-Wise Details</h3>
          
          <div style={{ margin: '0 20px' }}>
          <table style={{ width: '100%', textAlign: 'left', borderCollapse: 'collapse' }}>
              <thead> 
                  <tr style={{ backgroundColor: '#bdc3c7' }}>
                      <td style={{ border: '1px solid black', padding: '8px' }}>Product</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: 'right' }}>Amount&nbsp;</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: 'right' }}>Volume&nbsp;(L)</td>
                      <td style={{ border: '1px solid black', padding: '8px', textAlign: 'right' }}>Receipts</td>
                  </tr>
              </thead>
              <tbody>
                  {dataArray.map((row, index) => (
                      <tr key={index}>
                          <td style={{ border: '1px solid black', padding: '8px' }}>{row.product}</td>
                          <td style={{ border: '1px solid black', padding: '8px', textAlign: 'right' }}>{parseFloat(row.total_amount).toLocaleString('en-US')}</td>
                          <td style={{ border: '1px solid black', padding: '8px', textAlign: 'right' }}>{parseFloat(row.total_volume).toLocaleString('en-US')}</td>
                          <td style={{ border: '1px solid black', padding: '8px', textAlign: 'right' }}>{row.total_receipts}</td>
                      </tr>
                  ))}
              </tbody>
          </table>
    </div>      
          <h3 style={{ textAlign: 'center', marginTop: '20px' }}>****END OF PERIODIC REPORT****</h3>
      </div>
  );
};

const openReceiptPopup = (data, dataArray, station) => {
  const receiptContent = ReactDOMServer.renderToString(
    <ReceiptContent data={data} dataArray={dataArray} station={station} />
  );
  const receiptWindow = window.open('', '_blank', 'width=600,height=800,toolbar=no,menubar=no');

  receiptWindow.document.write('<html><head><title>Receipt</title></head><body>');

  // Include Font Awesome icons
  receiptWindow.document.write('<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.1/css/all.min.css">');

  receiptWindow.document.write(`
    <style>
        .toolbar {
            display: flex; 
            justify-content: space-between; 
            padding: 10px; 
            background-color: #f5f5f5; 
            border-bottom: 1px solid #e0e0e0;
        }
        .toolbar-button {
            background: none; 
            border: none; 
            cursor: pointer;
        }
        @media print {
            .toolbar {
                display: none;
            }
        }
    </style>
    `);

  // Create a custom toolbar at the top of the receipt
  receiptWindow.document.write(`
    <div class="toolbar">
        <span>Periodic Record</span>
        <div>
            <button onclick="window.print()" class="toolbar-button"><i class="fas fa-print"></i></button>
            <button id="download" class="toolbar-button"><i class="fas fa-download"></i></button>
        </div>
    </div>
    `);

  receiptWindow.document.write(receiptContent);
  receiptWindow.document.write('</body></html>');

  receiptWindow.document.close();

  receiptWindow.onload = function () {
    const downloadButton = receiptWindow.document.getElementById('download');

    downloadButton.addEventListener('click', function () {
      // Use html2canvas to capture the HTML content as an image
      html2canvas(receiptWindow.document.body, { dpi: 600 }).then((canvas) => {
        const imgData = canvas.toDataURL('image/jpeg', 2.0);

        // Create a PDF document using jsPDF
        const pdf = new jsPDF('p', 'mm', 'a4');
        pdf.addImage(imgData, 'PNG', 0, 0, 210, 320); // Use the dimensions as per your requirements

        // Save the PDF as a file
        pdf.save('receipt.pdf');
      });
    });
  };
};

//Styles for filter input fields.
const useStyles = makeStyles((theme) => ({
  formControl: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  FilterClearButtonClass: {
    float: 'right',
    margin: 10,
  },
}));

export default function CustomizedTables({token}) {

  const [currentDay, setCurrentDay] = useState({});
  const [previousDay, setPreviousDay] = useState({});
  const [currentMonth, setCurrentMonth] = useState({});
  const [previousMonth, setProviousMonth] = useState({});
  const [customPeriod, setCustomPeriod] = useState({});
  const [currentDayArray, setCurrentDayArray] = useState([]);
  const [previousDayArray, setPreviousDayArray] = useState([]);
  const [currentMonthArray, setCurrentMonthArray] = useState([]);
  const [previousMonthArray, setProviousMonthArray] = useState([]);
  const [customPeriodArray, setCustomPeriodArray] = useState([]);
  const [state, setState] = useState({ shift_period: "07:00", station_serial: ""});
  const [customState, setCustomState] = useState({since_date: "", to_date: ""});
  const [stationsArray, setStationsArray] = useState([]);
  const [selectedStation, setSelectedStation] = useState("");
  const classes = useStyles();

  const handleChange = (e) => {
    let value;
    const name = e.target.name;
  
    if (!isNaN(e.target.value) && e.target.value.length > 0) {
      value = Number(e.target.value);
    } else {
      value = e.target.value;
    }
  
    // If the name is either since_date or to_date, and the value is a string
    if ((name === 'since_date' || name === 'to_date') && typeof value === 'string') {
      // Replace 'T' with a space ' '
      value = value.replace('T', ' ');
  
      // Check if seconds are missing and append ':00' if needed
      const timeComponents = value.split(' ')[1].split(':');
      if (timeComponents.length === 2) {
        value = `${value}:00`;
      }
  
      setCustomState((prevState) => ({ ...prevState, [name]: value }));
    } else {
      setState((prevState) => ({ ...prevState, [name]: value }));
    }
  };
  
  
  

  useEffect(() => {
    //Fetch stations when filter bar is open
      fetch(`http://78.189.54.28:5000/station/all`, {
        headers: { Authorization: JSON.parse(sessionStorage.getItem('token')).data.access_token },
      })
        .then((response) => response.json())
        .then((data) => setStationsArray(data.data));
  }, [])

  useEffect(() => {

    let values = Object.fromEntries(Object.entries(state).filter(([_, v]) => v !== ''));  

    const fetchData = (url, setData, setArrayData) => {
        fetch(url, {
            method: 'POST',
            headers: {
                'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(values)
        })
        .then(response => response.json())
        .then(data => {
            setData(data);
            setArrayData(data.data);
        });
    };

    fetchData(`http://78.189.54.28:5000/sales_reports/periodic/current_day`, setCurrentDay, setCurrentDayArray);
    fetchData(`http://78.189.54.28:5000/sales_reports/periodic/previous_day`, setPreviousDay, setPreviousDayArray);
    fetchData(`http://78.189.54.28:5000/sales_reports/periodic/current_month`, setCurrentMonth, setCurrentMonthArray);
    fetchData(`http://78.189.54.28:5000/sales_reports/periodic/previous_month`, setProviousMonth, setProviousMonthArray);

}, [state]);

useEffect(() => {
  const payload = {
    since_date: customState.since_date,
    to_date: customState.to_date,
    station_serial: state.station_serial
  };

  const fetchData = (url, setData, setArrayData) => {
    fetch(url, {
      method: 'POST',
      headers: {
        'Authorization': JSON.parse(sessionStorage.getItem('token')).data.access_token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
    .then(response => response.json())
    .then(data => {
      setData(data);
      setArrayData(data.data);
    });
  };

  if (customState.since_date !== "" && customState.to_date !== "" && state.station_serial !== "")
    {
      fetchData(`http://78.189.54.28:5000/sales_reports/periodic`, setCustomPeriod, setCustomPeriodArray);
    }
}, [customState.since_date, customState.to_date, state.station_serial]);



  return (
    <div>
      <div className="FilterBar">
      <p><b>Select shift period (Start and end of shift).</b></p>
        <FormControl className={classes.formControl} noValidate>
          <TextField
              name="shift_period"
              onChange={handleChange}
              value={state.shift_period}
              id="date"
              label="Shift"
              type="time"
              className={classes.textField}
              InputLabelProps={{
                  shrink: true
              }}
              inputProps={{
                  step: "60"  // 60 seconds, so it increments by a minute
              }}
          />
        <FormHelperText className="FormHelperText">
          Select Shift Period (Start and end times will be the same)
        </FormHelperText>
      </FormControl>

      <div>
  {token.user_record.roles.id !== 1 && token.user_record.roles.id !== 3 ? (
    <></>
  ) : (
    <div>
    <FormControl>
      <Select
        labelId="demo-simple-select-placeholder-label-label"
        id="demo-simple-select-placeholder-label"
        name="station_serial"
        value={state.station_serial}
        onChange={handleChange}
        displayEmpty
        className={classes.selectEmpty}
      >
        <MenuItem value="" disabled>
          <em>Select Station</em>
        </MenuItem>
        {stationsArray.map((item, index) => (
          <MenuItem key={index} onClick={() => setSelectedStation(item.name)} value={item.serial_number}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>Available Stations</FormHelperText>
    </FormControl>
          <br/>
          <br/>
    <FormControl className={classes.formControl} noValidate>
      <TextField
        name="since_date"
        onChange={handleChange}
        value={customState.since_date}
        id="date"
        label="Custom Starting Date"
        type="datetime-local" // Change type to datetime-local
        className={classes.textField}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          step: "1" // Step in seconds, can be 1 for seconds precision
        }}
      />
      <FormHelperText className="FormHelperText">
        Since
      </FormHelperText>
    </FormControl>

    <FormControl className={classes.formControl} noValidate>
      <TextField
        name="to_date"
        onChange={handleChange}
        value={customState.to_date}
        id="date"
        label="Custom End Date"
        type="datetime-local" // Change type to datetime-local
        className={classes.textField}
        InputLabelProps={{
          shrink: true
        }}
        inputProps={{
          step: "1" // Step in seconds, can be 1 for seconds precision
        }}
      />
      <FormHelperText className="FormHelperText">
        To
      </FormHelperText>
    </FormControl>
    </div>
  )}
</div>
        </div>

    <div className="PeriodicContainer">

        <div className="PeriodicComponent">
          <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Daily (Current)</StyledTableCell>
            <StyledTableCell align="right">Amount&nbsp;(TL)</StyledTableCell>
            <StyledTableCell align="right">Volume&nbsp;(L)</StyledTableCell>
            <StyledTableCell align="right">Receipts</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentDayArray.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.product}
              </StyledTableCell>
              <StyledTableCell align="right">{parseFloat(row.total_amount).toLocaleString('en-US')}</StyledTableCell>
              <StyledTableCell align="right">{parseFloat(row.total_volume).toLocaleString('en-US')}</StyledTableCell>
              <StyledTableCell align="right">{row.total_receipts}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Button variant="primary" onClick={() => openReceiptPopup(currentDay, currentDayArray, selectedStation)}>Print</Button>
    </div>
        <div className="PeriodicComponent">
        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Daily (Previous)</StyledTableCell>
            <StyledTableCell align="right">Amount</StyledTableCell>
            <StyledTableCell align="right">Volume&nbsp;(L)</StyledTableCell>
            <StyledTableCell align="right">Receipts</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {previousDayArray.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.product}
              </StyledTableCell>
              <StyledTableCell align="right">{parseFloat(row.total_amount).toLocaleString('en-US')}</StyledTableCell>
              <StyledTableCell align="right">{parseFloat(row.total_volume).toLocaleString('en-US')}</StyledTableCell>
              <StyledTableCell align="right">{row.total_receipts}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Button variant="primary" onClick={() => openReceiptPopup(previousDay, previousDayArray, selectedStation)}>Print</Button>
        </div>
        <div className="PeriodicComponent"><TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Monthly (Current)</StyledTableCell>
            <StyledTableCell align="right">Amount&nbsp;(TL)</StyledTableCell>
            <StyledTableCell align="right">Volume&nbsp;(L)</StyledTableCell>
            <StyledTableCell align="right">Receipts</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentMonthArray.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.product}
              </StyledTableCell>
              <StyledTableCell align="right">{parseFloat(row.total_amount).toLocaleString('en-US')}</StyledTableCell>
              <StyledTableCell align="right">{parseFloat(row.total_volume).toLocaleString('en-US')}</StyledTableCell>
              <StyledTableCell align="right">{row.total_receipts}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Button variant="primary" onClick={() => openReceiptPopup(currentMonth, currentMonthArray, selectedStation)}>Print</Button>
    </div>
        <div className="PeriodicComponent"><TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Monthly (Previous)</StyledTableCell>
            <StyledTableCell align="right">Amount&nbsp;(TL)</StyledTableCell>
            <StyledTableCell align="right">Volume&nbsp;(L)</StyledTableCell>
            <StyledTableCell align="right">Receipts</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {previousMonthArray.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.product}
              </StyledTableCell>
              <StyledTableCell align="right">{parseFloat(row.total_amount).toLocaleString('en-US')}</StyledTableCell>
              <StyledTableCell align="right">{parseFloat(row.total_volume).toLocaleString('en-US')}</StyledTableCell>
              <StyledTableCell align="right">{row.total_receipts}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Button variant="primary" onClick={() => openReceiptPopup(previousMonth, previousMonthArray, selectedStation)}>Print</Button>
    </div>
    </div>
    <div className="PeriodicContainer2">
    <div className="PeriodicComponent"><TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Custom</StyledTableCell>
            <StyledTableCell align="right">Amount&nbsp;(TL)</StyledTableCell>
            <StyledTableCell align="right">Volume&nbsp;(L)</StyledTableCell>
            <StyledTableCell align="right">Receipts</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customPeriodArray.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.product}
              </StyledTableCell>
              <StyledTableCell align="right">{parseFloat(row.total_amount).toLocaleString('en-US')}</StyledTableCell>
              <StyledTableCell align="right">{parseFloat(row.total_volume).toLocaleString('en-US')}</StyledTableCell>
              <StyledTableCell align="right">{row.total_receipts}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <Button variant="primary" onClick={() => openReceiptPopup(customPeriod, customPeriodArray, selectedStation)}>Print</Button>
    </div>
    </div>
    </div>
  );
}
