import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Login.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';
// import { makeStyles } from '@material-ui/core/styles';

async function loginUser(credentials) {
    return fetch(`http://78.189.54.28:5000/auth/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({email: credentials.email, password: credentials.password})
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Response not OK.', response);
      }
      return response.json();
    })
    .catch((e) => {
      Swal.fire({
        title: 'Error!',
        text: 'Connection problem.',
        icon: 'error',
        confirmButtonText: 'Close'});
        credentials.setLoading(false);
  });
   }

export default function Login({ setToken }) {

  // const useStyles = makeStyles((theme) => ({
  //   formControl: {
  //     margin: theme.spacing(1),
  //     minWidth: 120,
  //     width: '100%',
  //   },
  //   selectEmpty: {
  //     marginTop: theme.spacing(2),
  //   },
  // }));

  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const[errorState, setErrorState] = useState({type: false, label: 'Password', helperText: ''})
  const [loading, setLoading] = React.useState(false);
  // const [environmentState, setEnvironmentState] = useState({environment: 'Production'});
  // const classes = useStyles();

  // function handleClick() {
    
  //   switch(environmentState.environment) {
  //     case "Production":
  //       window.sessionStorage.setItem("PumpIp", `${window.location.hostname}:9090`);
  //       window.sessionStorage.setItem("ReaderIp", `${window.location.hostname}:4000`);
  //       break;
  //     case "Testing":
  //       window.sessionStorage.setItem("PumpIp", `${window.location.hostname}:9095`);
  //       window.sessionStorage.setItem("ReaderIp", `${window.location.hostname}:2000`);
  //       break;  
  //     case "PTS React Development":
  //       window.sessionStorage.setItem("PumpIp", `78.189.54.28:9095`);
  //       window.sessionStorage.setItem("ReaderIp", `78.189.54.28:2000`);
  //       break;
  //     case "S4 React Development":
  //       window.sessionStorage.setItem("PumpIp", `78.189.54.28:9091`);
  //       window.sessionStorage.setItem("ReaderIp", `78.189.54.28:4001`);
  //       break;
  //     default:
  //       // code block
  //   }
    
  //   setLoading(true);
  // }

  // const handleEnvironmentChange = e => {

  //   const value = e.target.value;
  //   const name = e.target.name;

  //   setEnvironmentState(prevState => ({ ...prevState, [name]: value }));
    
  // }

  const handleSubmit = async e => {
    e.preventDefault();
    if (!email || !password){
      Swal.fire({
        title: 'Error!',
        text: 'Email and/or password cannot be empty!',
        icon: 'error',
        confirmButtonText: 'Try Again',
        confirmButtonColor: '#F27474'})
    }
    else{
      // handleClick();
    const token = await loginUser({
      email,
      password,
      setLoading
    });

    if(token?.status_code >= 200 && token?.status_code <= 299)
    {
      setToken(token);
    }

    else if(token?.status_code === 401){
      setLoading(false);
      setErrorState({type: true, label: 'Error', helperText: 'Check email and password.'});
  }}
  }

  return(
    <>
    <div className="login-wrapper">
      <h1>Please Log In</h1>
      <form id="loginForm" className="form-wrapper" onSubmit={handleSubmit}>

<div className="textField">
        <TextField
          error={errorState.type}
          id="outlined-error-helper-text"
          label="Email"
          onChange={e => setEmail(e.target.value)}
        />
        </div>

<div className="textField">
        <TextField
          error={errorState.type}
          id="outlined-password-helper-text"
          label="Password"
          type="password"
          helperText={errorState.helperText}
          onChange={e => setPassword(e.target.value)}
        />
        </div>

        {/* <FormControl>
                <Select
                  labelId="demo-simple-select-placeholder-label-label"
                  id="demo-simple-select-placeholder-label"
                  name="environment"
                  value={environmentState.environment}
                  onChange={handleEnvironmentChange}
                  defaultValue={"PTS"}
                  className={classes.selectEmpty}
                >
                  <MenuItem value="" disabled>
                    <em>Select Environment</em>
                  </MenuItem>
                      <MenuItem value="Production">Production</MenuItem>
                      <MenuItem value="Testing">Testing</MenuItem>
                      <MenuItem value="PTS React Development">Multisystem React Development</MenuItem>
                      <MenuItem value="S4 React Development">Single React Development</MenuItem>
                </Select>
                <FormHelperText>Operation Environment</FormHelperText>
              </FormControl> */}

        <br/>
        <div>
        <LoadingButton
        className="login-button"
        onClick={handleSubmit}
        endIcon={<SendIcon />}
        loading={loading}
        loadingPosition="end"
        variant="contained"
        type="submit"
        form="loginForm"
      >
        Login
      </LoadingButton>
        </div>
      </form>
    </div>
    </>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};